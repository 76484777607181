import React from 'react';
import {useTranslation} from "react-i18next";
import cookies from "js-cookie";

const AddedUsers = () => {
    const {t} = useTranslation()
    const lang = cookies.get('i18next')

    return (
        <div style={{fontSize:"18px",color:"#be0303",FontFamily:"KoHo"}}>
            {lang == 'am' ? 'ավելացված մարդիկ չկան' : lang == 'ru' ? 'Нет добавленных людей' : 'No people added'}
        </div>
    );
};

export default AddedUsers;