// styles
import "./ViewMachine.scss";

import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";


// custom imports
import Navbar from "../../components/navbar/Navbar";
import { API_URI, token } from "../../utils/keys";
import CreateDevice from "../../components/modals/createDevice";
import DeviceTable from "../../components/device/device";
import cookies from 'js-cookie';
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { goOffFreeMode, goOnFreeMode, goResetCount } from "../../redux/actions/deviceAction";
import UpdateData from "../../components/modals/updateData";
import ResetCounts from "../../components/modals/resetCounts";
import FreeModeflag from "../../components/modals/freeModeflag";
import DisableMode from "../../components/modals/disableMode";
import DateTimeSet from "../../components/modals/dateTimeSet";
import ChangeMachineName from "../../components/modals/changeMachineName";


const ViewMachine = ({ email, username, inputs, name, id }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [file, setFile] = useState("");
    const [listItems, setListItems] = useState([])
    const [items, setItems] = useState([])
    let params = useParams();
    const { t } = useTranslation()
    const userNumber = Number(params.userId)
    const [idd, setIdd] = useState([])
    const lang = cookies.get('i18next')

    useEffect(() => {
        const instance = axios.create({
            baseURL: `${API_URI}/car-wash`,
            headers: { 'Authorization': `Bearer ${token}` }
        });

        instance.get(`${API_URI}/car-wash`)
            .then(response => {
                setListItems(response.data)
            })
            .catch(e => {
                console.error(e, 'error')
            })
    }, [0])

    useEffect(() => {
        const instance = axios.create({
            baseURL: `${API_URI}/users`,
            headers: { 'Authorization': `Bearer ${token}` }
        });

        instance.get(`${API_URI}/users`)
            .then(response => {
                setItems(response.data.users)
            })
            .catch(e => {
                console.error(e, 'error')
            })
    }, [0])

    // const newArr = listItems.map(t1 => ({...t1, ...items.find(t2 => t2.id === t1.user_id)}))


    const merging_arr = listItems?.map(t1 => ({ ...t1, ...items.find(t2 => t1.user_id === t2.user_id) }))

    const single_machine = merging_arr?.filter(x => x.id === userNumber)

    //  const single_machine_test = listItems.filter(x => x.id === userNumber)
    const addUserHandler = (e) => {


        let cmdata = localStorage.getItem("bestToken")

        axios.post(`https://api.zxprof.com/cmd/nats-cnf`, { cmdata }).then(response => {
            if (response.data.data == 1) {
                Swal.fire({
                    title: lang === 'am' ? 'Տվյալները Թարմացել են' : lang === 'ru' ? 'данные обновлены' : 'Data Updated',
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    }
                })
                setTimeout(() => window.location.reload(), 1500)
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    timer: 1500,
                });
                setTimeout(() => window.location.reload(), 1500)
            }
        })
            .catch(e => {
                console.error(e)
            })
    }

    const onFreeModeHandler = e => {


        let cmdata = {}
        cmdata.devices = { id: [...idd] }
        cmdata.token = localStorage.getItem("bestToken")

        dispatch(goOnFreeMode(cmdata))

        navigate("/loadingPage")

    }

    const onFreeModeOffHandler = () => {
        let cmdata = {}
        cmdata.devices = { id: [...idd] }
        cmdata.token = localStorage.getItem("bestToken")

        dispatch(goOffFreeMode(cmdata))

        navigate("/loadingPage")
    }

    const resetCountsHandler = async (e) => {

        const cmdata = {}
        cmdata.devices = { id: [...idd] }
        cmdata.token = localStorage.getItem("bestToken")

        dispatch(goResetCount(cmdata))
        await axios.post(`https://api.zxprof.com/cmd/nats-cnf`, { cmdata: cmdata.token })
        navigate("/loadingPage")
    }

    const turnOnFunction = (e, id) => {
        const cmdata = {
            token: localStorage.getItem("bestToken"),
            devices: { id: [...idd] }
        }
        axios
            .post(`https://api.zxprof.com/cmd/nats-dena`,
                { cmdata }
            )
            .then((res) => {

                if (res.data.data == 1) {
                    Swal.fire({
                        icon: 'success',
                        text: `${lang == 'am' ? "ձեր հաշվիչները զրոյացվել են" : lang == 'ru' ? "ваши счетчики были сброшены" : "your counters have been reset"}`,
                    })
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",

                    });
                }
            })
            .catch((e) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    timer: 1500,
                });
            });
    }

    const turnOffFunction = (e, id) => {
        const cmdata = {
            token: localStorage.getItem("bestToken"),
            devices: { id: [...idd] }
        }
        axios
            .post(`https://api.zxprof.com/cmd/nats-ddis`,
                { cmdata }
            )
            .then((res) => {

                if (res.data.data == 1) {
                    Swal.fire({
                        icon: 'success',
                        text: `${lang == 'am' ? "ձեր հաշվիչները զրոյացվել են" : lang == 'ru' ? "ваши счетчики были сброшены" : "your counters have been reset"}`,
                    })
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",

                    });
                }
            })
            .catch((e) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    timer: 1500,
                });
            });
    }

    const turnDateSet = (e, id) => {
        const cmdata = {
            token: localStorage.getItem("bestToken"),
            devices: { id: [...idd] }
        }
        axios
            .post(`https://api.zxprof.com/cmd/nats-dts`,
                { cmdata }
            )
            .then((res) => {

                if (res.data.data == 1) {
                    Swal.fire({
                        icon: 'success',
                        text: `${lang == 'am' ? "ձեր հաշվիչները զրոյացվել են" : lang == 'ru' ? "ваши счетчики были сброшены" : "your counters have been reset"}`,
                    })
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",

                    });
                }
            })
            .catch((e) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    timer: 1500,
                });
            });
    }


    return (
        <div className="new">
            <div className="newContainer">
                <Navbar />

                <div className="view_slice">

                <div className="top_user">
                        <div>
                            <h1>{lang === 'am' ? 'Ավտոլվացման կետի անվանում' : lang === 'ru' ? 'Название автомойки' : 'Car wash Name'} - {single_machine && single_machine?.[0]?.car_wash_point_name}</h1>
                            <h1>{lang === 'am' ? 'Տեղակայման վայրը' : lang === 'ru' ? 'Местоположение' : 'Geolocation'} - {single_machine && lang === 'am' ? single_machine?.[0]?.MoykaProd?.place_am : lang === 'ru' ? single_machine?.[0]?.MoykaProd?.place_ru : single_machine?.[0]?.MoykaProd?.place_en}</h1>
                        </div>
                        {single_machine && <div><ChangeMachineName car_wash_point_name={single_machine?.[0]?.car_wash_point_name} MoykaProd={single_machine?.[0]?.MoykaProd} /></div>}

                    </div>
                    <div className="top">
                        {
                            single_machine?.map((info, k) => {
                                return (
                                    <Fragment key={k}>
                                        <h1>{info.username}</h1>
                                        <h3 style={{ color: "white" }}>{lang === 'am' ? 'Ստեղծվել է' : lang === 'ru' ? 'Создано на' : 'createdAt'} - {info?.createdAt.substring(0, 10)}</h3>
                                    </Fragment>
                                )
                            })
                        }
                    </div>
                    <div className="create_device"><CreateDevice /></div>

                </div>
                <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
                    <span >
                        <UpdateData success={idd.length > 0} />
                    </span>
                    <span >
                        <FreeModeflag idd={idd} one={false} success={idd.length > 0} />
                    </span>
                    <span >
                        <FreeModeflag idd={idd} one={true} success={idd.length > 0} />
                    </span>

                    <span >
                        <ResetCounts idd={idd} success={idd.length > 0} />
                    </span>
                    <span >
                        <DisableMode idd={idd} one={true} success={idd.length > 0} />
                    </span>
                    <span >
                        <DisableMode idd={idd} one={false} success={idd.length > 0} />
                    </span>
                    <span >
                        <DateTimeSet success={idd.length > 0} />
                    </span>
                </div>
                <br />
                <div className="device_slice">
                    <DeviceTable idd={idd} setIdd={setIdd} />
                </div>
            </div>
        </div>
    );
};

export default ViewMachine;