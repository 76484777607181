// styles
import "./ComponentsAdmin.scss"

import React, { useCallback, useEffect, useState } from 'react';
import cookies from "js-cookie";

// custom imports
import Navbar from "../navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { getComponentsIsAdmin } from "../../redux/actions/componentsAction";
import { useTranslation } from "react-i18next";
import CreateComponents from "../modals/createComponent";
import axios from "axios";
import { API_URI, token } from "../../utils/keys";
import EditComponent from "../modals/editComponent";
import DeleteModal from "../modals/deleteModal";



const ComponentsAdmin = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    let language = cookies.get("i18next")
    const [open, setOpen] = useState(false)

    useEffect(() => {
        dispatch(getComponentsIsAdmin())
    }, [])

    const getAllComponents = useSelector(state => state.componentsReducer.getComponentsIsAdmin)

    const removeComponent = (id) => {
        axios.post(`${API_URI}/total-component/remove`,
            { id: id },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
            .then(function (response) {
                dispatch(getComponentsIsAdmin())
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    const onDelete = (id) => {
        let a = window.confirm('Delete?')
        if (a) {
            removeComponent(id)
        }
    }


    return (
        <div className="admin_components_section">
            <Navbar />

            <div className="components_slice">
                <div className="components_main">
                    <h5>
                        {language == 'am' ? 'կոմպոնենտներ' : language == 'ru' ? 'Компоненты' : 'Components'}
                    </h5>
                    <CreateComponents />
                </div>

                <div className="main-container">
                    <ul className="grid-wrapper">
                        {
                            getAllComponents && getAllComponents?.map((t) => {
                                return (
                                    <>
                                        <li key={t?.id}>
                                            <div className="btn_slice">
                                                <span><EditComponent id={t?.id} name_en={t?.name_en} name_am={t?.name_am} name_ru={t?.name_ru} /></span>
                                                <span><i onClick={() => setOpen(true)} className="fa-solid fa-trash-can"></i></span>
                                            </div>
                                            {language === "en" && t?.name_en}
                                            {language === "am" && t?.name_am}
                                            {language === "ru" && t?.name_ru}
                                            <DeleteModal open={open} setOpen={setOpen} onDelete={() => onDelete(t?.id)} />
                                        </li>
                                    </>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ComponentsAdmin;