// styles
import "./modals.scss"

import * as React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

// custom imports
import closeButton from '../../images/closeIcon.svg'
//import {goAddTechnician} from "../../redux/actions/addTechnicianAction";
import { useNavigate, useParams } from "react-router-dom";
import { changeChannels, getChannels, goEditSettings, goEditSettingsBasic } from "../../redux/actions/settingsAction";
import { getComponents, getCounter } from "../../redux/actions/componentsAction";
import { useLayoutEffect } from "react";
import axios from 'axios'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export default function ChangeExtendedSettings({ verj, sf, deviceUser, vaha }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const components = useSelector(state => state.componentsReducer.components)
    const [a, setA] = useState(1)
    const channels = useSelector(state => state.componentsReducer.channels)
    useEffect(() => {
        dispatch(getComponents())
    }, [])
    useEffect(() => {
        dispatch(getChannels(+deviceUser))
    }, [deviceUser])

    const [open, setOpen] = React.useState(false);
    const [arr, setArr] = useState([])
    const [data, setData] = useState(sf)
    const lang = cookies.get('i18next')
    const qweqwe = localStorage.getItem("bestToken")
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const editSettings = async (e) => {
        e.preventDefault()

        const cmdata = {
            token: qweqwe,
            settings: data
        }
        const real = arr.filter((i) => i.comp_id !== null)

        const data2 = {
            pointId: qweqwe,
            ids: real,
            device_id: +deviceUser
        }

        setOpen(false)
        dispatch(goEditSettings(cmdata, data2))
	await axios.post(`https://api.zxprof.com/cmd/nats-cnf`, { token: cmdata?.token })
	navigate('/loadingPage')
    }
    useEffect(() => {

        sf?.component?.forEach((i, k) => {
            setArr(prev => [...prev, { indexnum: k, comp_id: null, id: 0 }])
        })
        setA(2)

    }, [])




    useEffect(() => {
        if (a === 2 && channels.length > 0) {
            channels?.forEach((i) => {

                arr.filter((z) => z.indexnum == i.indexnum).map((z) => {
                    z.comp_id = i.comp_id;
                    z.id = i.id
                })
                setArr([...arr])
            })
        }
    }, [a, channels])
    const handleCompChange = (e, k) => {
        arr[k].comp_id = Number(e.target.value);
        setArr([...arr])
    }


    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Button className="link" onClick={handleOpen}>
                {lang == 'am' ? 'փոխել ընդլայնված կարգավորումները' : lang == 'ru' ? 'Изменить расширенные настройки' : 'Change Extended Settings'}
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description">

                <div className="container_modal">
                    <form onSubmit={editSettings} id="contact"
                        style={{ overflowY: "scroll", height: "500px", justifyContent: "flex-start" }}>
                        <h3>
                            {lang == 'am' ? 'փոխել ընդլայնված կարգավորումները' : lang == 'ru' ? 'Изменить расширенные настройки' : 'Change Extended Settings'}
                        </h3>
                        {(data?.mode || data?.mode === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Ռեժիմ' : lang === 'ru' ? 'Режим' : 'Mode'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Ռեժիմ' : lang === 'ru' ? 'Режим' : 'Mode'}
                                type="number"
                                defaultValue={data.mode}
                                onChange={e => {
                                    data.mode = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.bpEn || data?.bpEn === 0) && <fieldset>
                            <h6>bpEn</h6>
                            <input
                                placeholder='bpEn'
                                type="number"
                                defaultValue={data.bpEn}
                                onChange={e => {
                                    data.bpEn = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.bpTime || data?.bpTime === 0) && <fieldset>
                            <h6>bpTime</h6>
                            <input
                                placeholder='bpTime'
                                type="number"
                                defaultValue={data.bpTime}
                                onChange={e => {
                                    data.bpTime = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.bpCh) && <fieldset>
                            <h6>bpCh</h6>
                            {data.bpCh.map((i, k) => (
                                <input
                                    placeholder={k + 1}
                                    type="number"
                                    defaultValue={data.bpCh[k]}
                                    onChange={e => {
                                        data.bpCh[k] = Number(e.target.value)
                                        setData({ ...data })
                                    }
                                    }
                                    tabIndex="3"
                                    required
                                    key={k} />
                            ))}

                        </fieldset>}
                        {(data?.service || data?.service === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Սերվիս' : lang === 'ru' ? 'Сервис' : 'Service'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Սերվիս' : lang === 'ru' ? 'Сервис' : 'Service'}
                                type="number"
                                defaultValue={data.service}
                                onChange={e => {
                                    data.service = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.hopper?.enabled || data?.hopper?.enabled === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Միացված է' : lang === 'ru' ? 'Включено' : 'Enabled'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Միացված է' : lang === 'ru' ? 'Включено' : 'Enabled'}
                                type="number"
                                defaultValue={data.hopper.enabled}
                                onChange={e => {
                                    data.hopper.enabled = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.hopper?.nominal || data?.hopper?.nominal === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Անվանական' : lang === 'ru' ? 'Номинальный' : 'Nominal'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Անվանական' : lang === 'ru' ? 'Номинальный' : 'Nominal'}
                                type="number"
                                defaultValue={data.hopper.nominal}
                                onChange={e => {
                                    data.hopper.nominal = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.hopper?.timeout || data?.hopper?.timeout === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Ընդմիջում' : lang === 'ru' ? 'Тайм-аут' : 'Timeout'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Ընդմիջում' : lang === 'ru' ? 'Тайм-аут' : 'Timeout'}
                                type="number"
                                defaultValue={data.hopper.timeout}
                                onChange={e => {
                                    data.hopper.timeout = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.hopper?.threshold || data?.hopper?.threshold === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'շեմ' : lang === 'ru' ? 'Порог' : 'Threshold'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'շեմ' : lang === 'ru' ? 'Порог' : 'Threshold'}
                                type="number"
                                defaultValue={data.hopper.threshold}
                                onChange={e => {
                                    data.hopper.threshold = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.valve?.period || data?.valve?.period === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Ժամանակաշրջան' : lang === 'ru' ? 'Период' : 'Period'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Ժամանակաշրջան' : lang === 'ru' ? 'Период' : 'Period'}
                                type="number"
                                defaultValue={data.valve.period}
                                onChange={e => {
                                    data.valve.period = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}

                        {(data?.valve?.duration || data?.valve?.duration === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Տևողությունը' : lang === 'ru' ? 'Продолжительность' : 'Duration'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Տևողությունը' : lang === 'ru' ? 'Продолжительность' : 'Duration'}
                                type="number"
                                defaultValue={data.valve.duration}
                                onChange={e => {
                                    data.valve.duration = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}

                        {(data?.valve?.count || data?.valve?.count === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Հաշվել' : lang === 'ru' ? 'Считать' : 'Count'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Հաշվել' : lang === 'ru' ? 'Считать' : 'Count'}
                                type="number"
                                defaultValue={data.valve.count}
                                onChange={e => {
                                    data.valve.count = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.tickerEnabled || data?.tickerEnabled === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Ticker-ը միացված է' : lang === 'ru' ? 'Тикер включен' : 'Ticker enabled'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Ticker-ը միացված է' : lang === 'ru' ? 'Тикер включен' : 'Ticker enabled'}
                                type="number"
                                defaultValue={data.tickerEnabled}
                                onChange={e => {
                                    data.tickerEnabled = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.currency || data?.currency === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Արժույթ' : lang === 'ru' ? 'Валюта' : 'Currency'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Արժույթ' : lang === 'ru' ? 'Валюта' : 'Currency'}
                                type="number"
                                defaultValue={data.currency}
                                onChange={e => {
                                    data.currency = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.dColor || data?.dColor === 0) && <fieldset>
                            <h6>dColor</h6>
                            <input
                                placeholder='dColor'
                                type="number"
                                defaultValue={data.dColor}
                                onChange={e => {
                                    data.dColor = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {data?.color && <fieldset>
                            <h6>{lang === 'am' ? 'Գույն' : lang === 'ru' ? 'Цвет' : 'Color'}</h6>
                            {data.color.map((i, k) => (
                                <input
                                    placeholder={k + 1}
                                    type="number"
                                    defaultValue={data.color[k]}
                                    onChange={e => {
                                        data.color[k] = Number(e.target.value)
                                        setData({ ...data })
                                    }
                                    }
                                    tabIndex="3"
                                    required
                                    key={k} />
                            ))}
                        </fieldset>}
                        {data?.component && <fieldset>
                            <h6>{lang === 'am' ? 'Կոմպոնենտ' : lang === 'ru' ? 'Компонент' : 'Component'}</h6>
                            {data.component.map((i, k) => (
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 10, marginTop: 10 }} key={k} >
                                    {vaha && <select name="component_name" defaultValue={arr?.[k]?.comp_id} onChange={(e) => handleCompChange(e, k)}>
                                        <option value={null}>
                                            {lang == 'am' ? 'ընտրել' : lang == 'ru' ? 'Выбрать' : 'Select'}
                                        </option>
                                        {
                                            verj?.Components?.map((component) => {
                                                return (
                                                    <option
                                                        name="component_name"
                                                        key={component?.id}
                                                        value={component?.id}>
                                                        {lang == "en" && component?.name_en}
                                                        {lang == "am" && component?.name_am}
                                                        {lang == "ru" && component?.name_ru}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>}
                                    <input
                                        placeholder={k + 1}
                                        type="number"
                                        defaultValue={data.component[k]}
                                        onChange={e => {
                                            data.component[k] = Number(e.target.value)
                                            setData({ ...data })
                                        }
                                        }
                                        tabIndex="3"
                                        required
                                    />
                                </div>
                            ))}
                        </fieldset>}
                        {data?.screen && <fieldset>
                            <h6>{lang === 'am' ? 'Էկրան' : lang === 'ru' ? 'Экран' : 'Screen'}</h6>
                            {data.screen.map((i, k) => (
                                <input
                                    placeholder={k + 1}
                                    type="number"
                                    defaultValue={data.screen[k]}
                                    onChange={e => {
                                        data.screen[k] = Number(e.target.value)
                                        setData({ ...data })
                                    }
                                    }
                                    tabIndex="3"
                                    required
                                    key={k} />
                            ))}
                        </fieldset>}
                        {data?.screenColor && <fieldset>
                            <h6>{lang === 'am' ? 'Էկրանի գույնը' : lang === 'ru' ? 'Цвет экрана' : 'Screen color'}</h6>
                            {data.screenColor.map((i, k) => (
                                <input
                                    placeholder={k + 1}
                                    type="number"
                                    defaultValue={data.screenColor[k]}
                                    onChange={e => {
                                        data.screenColor[k] = Number(e.target.value)
                                        setData({ ...data })
                                    }
                                    }
                                    tabIndex="3"
                                    required
                                    key={k} />
                            ))}
                        </fieldset>}
                        {(data?.bonusMode || data?.bonusMode === 0) && <fieldset>
                            <h6> {lang === 'am' ? 'բոնուս մոդ' : lang === 'ru' ? 'бонусный мод' : 'bonus mode'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'բոնուս մոդ' : lang === 'ru' ? 'бонусный мод' : 'bonus mode'}
                                type="number"
                                tabIndex="1"
                                defaultValue={data.bonusMode}
                                onChange={e => {
                                    data.bonusMode = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                required
                            />
                        </fieldset>}
                        {(data?.pauseMode || data?.pauseMode === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Դադարի ռեժիմ' : lang === 'ru' ? 'Режим паузы' : 'Pause mode'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Դադարի ռեժիմ' : lang === 'ru' ? 'Режим паузы' : 'Pause mode'}
                                type="number"
                                tabIndex="1"
                                defaultValue={data.pauseMode}
                                onChange={e => {
                                    data.pauseMode = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                required
                            />
                        </fieldset>}
                        {(data?.hpt || data?.hpt === 0) && <fieldset>
                            <h6>hpt</h6>
                            <input
                                placeholder='hpt'
                                type="number"
                                defaultValue={data.hpt}
                                onChange={e => {
                                    data.hpt = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {data?.flowSensor && <fieldset>
                            <h6>{lang === 'am' ? 'Հոսքի սենսոր' : lang === 'ru' ? 'Датчик расхода' : 'Flow sensor'}</h6>
                            {data.flowSensor.map((i, k) => (
                                <React.Fragment key={k}>
                                    <h6>{k + 1}</h6>
                                    <div style={{ display: 'flex', gap: 10 }}>
                                        <h6>{lang === 'am' ? 'պուլս' : lang === 'ru' ? 'пульс' : 'pulse'}</h6>
                                        <input
                                            placeholder={k + 1}
                                            type="number"
                                            defaultValue={data.flowSensor[k].pulse}
                                            onChange={e => {
                                                data.flowSensor[k].pulse = Number(e.target.value)
                                                setData({ ...data })
                                            }
                                            }
                                            tabIndex="3"
                                            required />
                                    </div>

                                    <div style={{ display: 'flex', gap: 10 }}>
                                        <h6>{lang === 'am' ? 'ընդմիջում' : lang === 'ru' ? 'тайм-аут' : 'timeout'}</h6>
                                        <input
                                            placeholder={k + 1}
                                            type="number"
                                            defaultValue={data.flowSensor[k].timeout}
                                            onChange={e => {
                                                data.flowSensor[k].timeout = Number(e.target.value)
                                                setData({ ...data })
                                            }
                                            }
                                            tabIndex="3"
                                            required />
                                    </div>
                                </React.Fragment>
                            ))}
                        </fieldset>}

                        <fieldset>
                            <button type="submit" id="contact-submit">
                                {lang === 'am' ? 'Պահպանել' : lang === 'ru' ? 'Сохранить' : 'Save'}
                            </button>
                        </fieldset>
                        <div onClick={handleClose} className="closeBtn">
                            <img src={closeButton} alt="close icon" />
                        </div>
                    </form>
                </div>
            </Modal >
        </div >
    );
}
