// styles
import './chart.scss'
import React, { useEffect, useState } from "react";
import {
    BarChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Area,
    Bar,
    LineChart
} from "recharts";
import { useTranslation } from "react-i18next";
import { API_URI, token } from "../../utils/keys";
import axios from "axios";
import cookies from "js-cookie";
import Navbar from '../navbar/Navbar';
import { Button, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Loader from '../../images/loader.png'

export default function AnaliticsUserDashboard() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [totalBillPoint, setTotalBillPoint] = useState(0)
    const [totalCoin, setTotalCoin] = useState(0)
    const [cashless, setCashless] = useState(0)
    const [start, setStart] = useState([])
    const [end, setEnd] = useState([])
    const [vid, setVid] = useState([])
    const [value, setValue] = useState([])
    const [value1, setValue1] = useState([])
    const [verj, setVerj] = useState([])
    const [qwe, setQwe] = useState([])
    const [devices, setDevices] = useState([])
    const [gumar, setGumar] = useState([])
    const [yntacik, setYntacik] = useState([])
    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState([])
    const [render, setRender] = useState(0)
    const [loader, setLoader] = useState([])
    const currentDate = new Date();

    const lang = cookies.get('i18next')
    useEffect(() => {
        axios.get(`${API_URI}/car-wash/`, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then((response) => response.data)
            .then(async data => {
                setVerj(data)

                setValue([])
                if (data.length != 0) {
                    let k = []
                    let forVid = []
                    let wantedQwe = []
                    let wantedValue1 = []
                    let wantedStart = []
                    let wantedEnd = []
                    let wantedLoader = []
                    data.forEach((i) => {
                        k.push(i.token)
                        forVid.push([])
                        wantedQwe.push(0)
                        wantedValue1.push('coin')
                        wantedStart.push(moment(new Date()).subtract(1, 'month').format('YYYY-MM-DD'))
                        wantedEnd.push(moment(new Date()).format('YYYY-MM-DD'))
                        wantedLoader.push(true)
                    })
                    setLoader(wantedLoader)
                    setStart(wantedStart)
                    setEnd(wantedEnd)
                    setValue(k)
                    setValue1(wantedValue1)
                    setVid(forVid)
                    setTotal(forVid)
                    setQwe(wantedQwe)


                }
            })

    }, [])


    useEffect(() => {
        setGumar([])
        setYntacik([])
        if (value && (value.length != 0)) {
            axios.post('https://api.zxprof.com/api/nats-allcnf', {
                arr: value
            })
                .then((res) => res.data)
                .then((data) => {
                    data.forEach((val) => {
                        const asd = JSON.parse(val.dataMsg)
                        let gumarAll = 0;
                        let yntacikAll = 0
                        asd.forEach((i) => {
                            gumarAll += i.bill.t + i.cashless.t + i.coin.t
                            yntacikAll += i.bill.d + i.cashless.d + i.coin.d
                        })
                        setGumar(prev => [...prev, gumarAll])
                        setYntacik(prev => [...prev, yntacikAll])
                    })
                })
        }
    }, [value])


    //656ac46c-c3b3-4f1d-a514-e9a4b62f2d6b
    const handleClick = async (wantedIndex) => {
        // axios.get(`${API_URI}/nats-cnf?` + new URLSearchParams({
        //     start, end, pId: value
        // }))
        loader[wantedIndex] = true
        setLoader([...loader])
        await axios.get(`https://api.zxprof.com/api/nats-cnf?start=${new Date(start[wantedIndex])}&end=${new Date(end[wantedIndex])}&pId=${value[wantedIndex]}`)
            .then((response) => response.data)
            .then((data) => data.dms)
            .then((val) => {
                vid[wantedIndex] = []
                setVid([...vid])
                // if (val[0]?.dataMsg) {

                    let wantedData = []
                    let wantedAsd = []


                    val.forEach((element, index) => {
                        if (element) {
                            if (element.dataMsg) {
                                const asd = JSON.parse(element.dataMsg)


                                wantedData = asd
                                wantedAsd.push({ data: asd, createdAt: element.createdAt })

                            }
                        } else {
                            wantedAsd.push(null)
                        }
                    });
                    let wantedTotal = total
                    wantedTotal[wantedIndex] = wantedData
                    let wantedVid = vid
                    wantedVid[wantedIndex] = wantedAsd


                    // setTotal([...total])
                    // setTotalBillPoint(asd[qwe].bill.t + asd[qwe].bill.d);
                    // setTotalCoin(asd[qwe].coin.t + asd[qwe].coin.d);
                    // setCashless(asd[qwe].cashless.t + asd[qwe].cashless.d)

                    loader[wantedIndex] = false
                    setLoader([...loader])
                    setTotal(wantedTotal)
                    setVid(wantedVid)
                // }
            })
        setRender(prev => prev + 1)
    }
    const handleUseEffect = async () => {
        // axios.get(`${API_URI}/nats-cnf?` + new URLSearchParams({
        //     start, end, pId: value
        // }))
        await axios.get(`https://api.zxprof.com/api/nats-cnf-arr?start=${new Date(start[0])}&end=${new Date(end[0])}&pId=${value}`)
            .then((response) => response.data)
            .then((data) => {
               
                data.dms?.forEach((val, k) => {

                    // if (val[0]?.dataMsg) {

                        let wantedData = []
                        let wantedAsd = []
                        val.forEach((element, index) => {
                            if (element) {
                                if (element.dataMsg) {
                                    const asd = JSON.parse(element.dataMsg)
                                    wantedData = asd
                                    wantedAsd.push({ data: asd, createdAt: element.createdAt })

                                }
                            } else {
                                wantedAsd.push(null)
                            }
                        });
                        let wantedTotal = total
                        wantedTotal[k] = wantedData
                        let wantedVid = vid
                        wantedVid[k] = wantedAsd


                        // setTotal([...total])
                        // setTotalBillPoint(asd[qwe].bill.t + asd[qwe].bill.d);
                        // setTotalCoin(asd[qwe].coin.t + asd[qwe].coin.d);
                        // setCashless(asd[qwe].cashless.t + asd[qwe].cashless.d)
                        setTotal(wantedTotal)
                        setVid(wantedVid)
                    // }
                })


            })
        setRender(prev => prev + 1)
        const wantedLoaderArr = []
        loader.forEach(() => wantedLoaderArr.push(false))
        setLoader(wantedLoaderArr)
    }

    useEffect(() => {
        if (verj.length > 0) {
            handleUseEffect()
        }
    }, [verj])
    useEffect(() => {
        const instance = axios.create({
            baseURL: `${API_URI}/auth/me`,
            headers: { 'Authorization': `Bearer ${token}` }
        });

        instance.get(`${API_URI}/car-wash/device`)
            .then(response => {

                setDevices(response.data)
            })
            .catch(e => {
                console.error(e)
            })

    }, [])


    const onVerj = (e) => {
        localStorage.setItem("bestToken", e)
    }
    return (
        <>
            {loading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: '2rem', color: 'white' }}>Loading... </div> : <div className="washing_machines_section_user">
                <div className="washing_machines_container">
                    {
                        verj && verj.length == 0 && <div className="not_added_car_wash">{lang === 'am' ? 'ձեր համար ավտոլվացման կետեր ստեղծված չեն' : lang === 'ru' ? 'Для вас нет автомоек' : 'There are no car wash points for you'}</div>
                    }
                    <div className="washing_machines_slice" style={{ padding: 0 }}>
                        {
                            verj?.map((info, k) => {
                                const wantedVid = []
                                let tempData = {}
                                const START = moment.utc(start[k]).startOf('day');
                                const NOW = moment.utc(end[k]).endOf('day');

                                let current = moment.utc(START);

                                const isLessThanTwoDays = NOW.diff(START, 'days') < 2;
                                const isMoreThanMonth = current.clone().add(1, 'month').add(1, 'day').isSameOrBefore(NOW);



                                vid[k]?.forEach((i, wantedK) => {
                                    let wantedCurrent = ''
                                    if (isLessThanTwoDays) {
                                        wantedCurrent = current.clone().add(wantedK, 'hours').format('MM/DD/YYYY HH:mm');
                                    } else if (isMoreThanMonth) {
                                        wantedCurrent = current.clone().add(wantedK, 'months').format('MM/YYYY')
                                    } else {
                                        wantedCurrent = current.clone().add(wantedK, 'days').format('MM/DD/YYYY');
                                    }
                                    if (wantedK !== 0) {
                                        const wantedCoin = +i?.data[qwe[k]]?.coin.t && +tempData.coinT && +tempData.coinT !== 0 ? +i?.data[qwe[k]]?.coin.t - +tempData.coinT : 0;
                                        const wantedBill = +i?.data[qwe[k]]?.bill.t && +tempData.billT && +tempData.billT !== 0 ? +i?.data[qwe[k]]?.bill.t - +tempData.billT : 0;
                                        const wantedCashless = +i?.data[qwe[k]]?.cashless.t && +tempData.cashlessT && +tempData.cashlessT !== 0 ? +i?.data[qwe[k]]?.cashless.t - +tempData.cashlessT : 0;
                                        const test2 = {
                                            name: wantedCurrent,
                                            coinT: (!wantedCoin || wantedCoin < 0) ? 0 : wantedCoin,
                                            // coinD: i.data[qwe[k]]?.coin.d,
                                            billT: (!wantedBill || wantedBill < 0) < 0 ? 0 : wantedBill,
                                            // billD: i.data[qwe[k]]?.bill.d,
                                            cashlessT: (!wantedCashless || wantedCashless < 0) < 0 ? 0 : wantedCashless,
                                            // cashlessD: i.data[qwe[k]]?.cashless.d,

                                        }
                                        tempData = {

                                            coinT: +i?.data[qwe[k]]?.coin.t || +tempData.coinT || 0,
                                            // coinD: i.data[qwe[k]]?.coin.d,
                                            billT: +i?.data[qwe[k]]?.bill.t || +tempData.billT || 0,
                                            // billD: i.data[qwe[k]]?.bill.d,
                                            cashlessT: +i?.data[qwe[k]]?.cashless.t || +tempData.cashlessT || 0,
                                            // cashlessD: i.data[qwe[k]]?.cashless.d,

                                        }
                                        wantedVid.push(test2)
                                    } else {
                                        tempData = {
                                            coinT: +i?.data[k]?.coin.t || +tempData.coinT || 0,

                                            billT: +i?.data[k]?.bill.t || +tempData.billT || 0,
                                            cashlessT: +i?.data[k]?.cashless.t || +tempData.cashlessT || 0,

                                        }
                                    }
                                })

				const wantedDeviecesArr = []
				let wantedGumar = 0
                                let wantedYntacik = 0
                                devices?.filter((zx => zx.car_wash_point_id == info.id)).forEach((jkl, h) => {
                                    wantedDeviecesArr.push(h + 1)
				    wantedGumar += (jkl?.Counter?.billT + jkl?.Counter?.coinT + jkl?.Counter?.cashlessT) || 0
                                    wantedYntacik += (jkl?.Counter?.billD + jkl?.Counter?.coinD + jkl?.Counter?.cashlessD) || 0
                                })
                                return (
                                    <div key={info?.id} style={{ display: 'flex', flexDirection: 'column', border: '1px solid rgb(189, 180, 180)', padding: '5px 5px', alignItems: 'space-between', justifyContent: 'flex-start' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 10 }}>
                                            <Card >
                                                <CardActionArea>
                                                    <h2 className="carwash_username">{lang === 'am' ? 'Ավտոլվացման կետի անվանում' : lang === 'ru' ? 'Название автомойки' : 'Car wash Name'} -
                                                        {info?.car_wash_point_name === null ? (lang === 'am' ? 'Անվանում դեռ ավելացված չէ' : lang === 'ru' ? 'Имя еще не добавлено' : 'Name not added yet') : info?.car_wash_point_name} </h2>
                                                    <CardContent>
                                                        <Typography variant="body2" color="text.secondary.pink">
                                                            {lang === 'am' ? 'Սարքեր' : lang === 'ru' ? 'Устройство' : 'Devices'} - {devices?.filter((zx => zx.car_wash_point_id === info.id))?.length}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary.pink">
                                                            {info?.MoykaProd !== null ? (lang === 'am' ? `Տեղակայման վայրը - ${info.MoykaProd.place_am}` : lang === 'ru' ? `Местоположение - ${info.MoykaProd.place_ru}` : `Geolocation - ${info.MoykaProd.place_en}`) : (lang === 'am' ? 'Տեղակայման վայրը դեռ ավելացված չէ' : lang === 'ru' ? 'Местоположение еще не добавлено' : 'Geolocation not added yet')}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary.pink">
                                                            {lang === 'am' ? 'Գումարի ընդհանուր չափ' : lang === 'ru' ? 'Общая сумма денег' : 'Total amount of money'}  - {wantedGumar} {lang === 'am' ? 'դրամ' : lang === 'ru' ? 'монет' : 'coin'}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary.pink">
                                                            {lang === 'am' ? 'Ընթացիկ դրամարկղ' : lang === 'ru' ? 'Текущая касса' : 'Current cash register'} - {wantedYntacik} {lang === 'am' ? 'դրամ' : lang === 'ru' ? 'монет' : 'coin'}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary.pink">
                                                            {lang === 'am' ? 'Ստեղծվել է' : lang === 'ru' ? 'Создано на' : 'createdAt'} - {info?.createdAt?.substring(0, 10)}
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                                <div className="buttons_carwash_user">
                                                    <div onClick={async () => {
                                                        setLoading(true)
                                                        await onVerj(info?.token)
                                                        await axios.post(`https://api.zxprof.com/cmd/nats-cnf`, { cmdata: info?.token })
                                                        setTimeout(() => {
                                                            setLoading(false)
                                                            navigate(`/viewMachineUser/${info?.id}`)

                                                        }, 1200)

                                                    }}><button className="edit_btn">{lang === 'am' ? 'Տեսնել' : lang === 'ru' ? 'Посмотреть' : 'View'}</button></div>
                                                </div>
                                            </Card>
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>

                                                <h3 className="income_graph_text">
                                                    {lang == 'am' ? 'եկամուտների գրաֆիկ' : lang == 'ru' ? 'график доходов' : 'Income Graph'}
                                                </h3>
                                                {loader[k] ? <div style={{ width: 600, height: 230, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={Loader} className='spinnerForever' style={{ width: 100, height: 100 }} alt='' />
                                                </div> :
                                                    <BarChart
                                                        width={600}
                                                        height={230}
                                                        data={wantedVid}
                                                        margin={{
                                                            top: 20,
                                                            right: 80,
                                                            bottom: 20,
                                                            left: 20
                                                        }}
                                                        style={{ margin: "0 auto" }}>


                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="name" />
                                                        <YAxis />
                                                        <Tooltip />
                                                        <Legend />
                                                        {value1[k] === 'coin' && <Bar type="monotone" dataKey="coinT" fill="#82ca9d" />}
                                                        {value1[k] === 'bill' && <Bar type="monotone" dataKey="billT" fill="#82ca9d" />}
                                                        {value1[k] === 'cashless' && <Bar type="monotone" dataKey="cashlessT" fill="#82ca9d" />}
                                                    </BarChart>}


                                                {/* <div className="wrapper_block">
                                                    <span className="car_wash_bill_point">
                                                        {lang == 'am' ? 'Թղթադրամներով գումար' : lang == 'ru' ? 'Деньги в банкнотах' : 'Money in banknotes'} - {total[k]?.[qwe[k]]?.bill.t + total[k]?.[qwe[k]]?.bill.d}  {lang === 'am' ? 'դրամ' : lang === 'ru' ? 'монет' : 'coin'}
                                                    </span>
                                                    <span className="car_wash_bill_point">
                                                        {lang == 'am' ? 'Մետաղադրամներով գումար' : lang == 'ru' ? 'деньги в монетах' : 'money in coins'} - {total[k]?.[qwe[k]]?.coin.t + total[k]?.[qwe[k]]?.coin.d}  {lang === 'am' ? 'դրամ' : lang === 'ru' ? 'монет' : 'coin'}
                                                    </span>
                                                    <span className="car_wash_bill_point">
                                                        {lang == 'am' ? 'Անկանխիկ վճարումների գումար' : lang == 'ru' ? 'Безналичный расчет' : 'Cashless Payments'} - {total[k]?.[qwe[k]]?.cashless.t + total[k]?.[qwe[k]]?.cashless.d}  {lang === 'am' ? 'դրամ' : lang === 'ru' ? 'монет' : 'coin'}
                                                    </span>
                                                    <span className="car_wash_bill_point">
                                                        {lang == 'am' ? 'ընդհանուր գումար' : lang == 'ru' ? 'Итого' : 'total amount'} - {total[k]?.[qwe[k]]?.bill.t + total[k]?.[qwe[k]]?.bill.d + total[k]?.[qwe[k]]?.coin.t + total[k]?.[qwe[k]]?.coin.d + total[k]?.[qwe[k]]?.cashless.t + total[k]?.[qwe[k]]?.cashless.d}  {lang === 'am' ? 'դրամ' : lang === 'ru' ? 'монет' : 'coin'}
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='veryNew' style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', alignSelf: 'flex-end' }} >
                                            <div >
                                                <label htmlFor="start" style={{ color: 'white' }}>{lang == 'am' ? 'Սարք' : lang == 'ru' ? 'Устройство' : 'Device'}:</label>
                                                <select defaultValue={qwe[k]} onChange={(e) => {
                                                    qwe[k] = e.target.value - 1
                                                    setQwe([...qwe])
                                                }}>
                                                    {wantedDeviecesArr?.map((i) => (
                                                        <option value={i} key={i}>{i}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div >
                                                <label htmlFor="start" style={{ color: 'white' }}>{lang == 'am' ? 'Տիպ' : lang == 'ru' ? 'Тип' : 'Type'}:</label>
                                                <select defaultValue={'coin'} onChange={(e) => {
                                                    value1[k] = e.target.value
                                                    setValue1([...value1])
                                                }}>
                                                    <option value={'coin'}>  {lang == 'am' ? 'Մետաղադրամ' : lang == 'ru' ? 'Монета' : 'Coin'}</option>
                                                    <option value={'bill'}> {lang == 'am' ? 'Բիլլ' : lang == 'ru' ? 'Счет' : 'Bill'}</option>
                                                    <option value={'cashless'}> {lang == 'am' ? 'Անկանխիկ' : lang == 'ru' ? 'Безналичный' : 'Cashless'}</option>
                                                </select>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-start' }}>
                                                <label htmlFor="start" style={{ color: 'white', fontSize: 11 }}>{lang == 'am' ? 'Մեկնարկի ամսաթիվ' : lang == 'ru' ? 'Дата начала' : 'Start date'}:</label>

                                                <input type="date" max={new Date(end?.[k])?.toISOString().split('T')[0]} style={{ padding: 0 }}
                                                    value={start[k]} onChange={(e) => {
                                                        start[k] = e.target.value
                                                        setStart([...start])
                                                    }}
                                                />
                                            </div>
                                            <fieldset style={{ display: 'flex', flexDirection: 'column' }}>
                                                <label htmlFor="start" style={{ color: 'white', fontSize: 11 }}>{lang == 'am' ? 'Ավարտի ամսաթիվ' : lang == 'ru' ? 'Дата окончания' : 'End date'}:</label>

                                                <input type="date" id="start" name="trip-start" min={new Date(start?.[k])?.toISOString().split('T')[0]} max={currentDate.toISOString().split('T')[0]} style={{ padding: 0 }}
                                                    value={end[k]} onChange={(e) => {
                                                        end[k] = e.target.value
                                                        setEnd([...end])
                                                    }}
                                                />
                                            </fieldset>
                                            <div className='asdasd'>
                                                <Button disabled={loader[k]} onClick={async () => await handleClick(k)} >{lang == 'am' ? 'ՄՈՒՏՔԱԳՐԵԼ' : lang == 'ru' ? 'ВСТАВИТЬ' : 'SUBMIT'}</Button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>}
        </>

    );
}
