// styles
import "./modals.scss"


import * as React from 'react';
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

// custom imports
import closeButton from '../../images/closeIcon.svg'
//import {goAddTechnician} from "../../redux/actions/addTechnicianAction";
import axios from "axios";
import { API_URI, token } from "../../utils/keys";
import { goAddMachine } from "../../redux/actions/addMachineAction";
import { useTranslation } from "react-i18next";
import { editMachineName } from "../../redux/actions/changeMachineName.Action";
import cookies from "js-cookie";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export default function ChangeMachineName({car_wash_point_name, MoykaProd, user_id}) {
    const dispatch = useDispatch()
    const { userId } = useParams();
    let navigate = useNavigate();
    const { t } = useTranslation()
    const [open, setOpen] = React.useState(false);
    const [error, setError] = useState(false)
    const [data, setData] = useState({
        carWashId: user_id ? user_id : userId,
        car_wash_point_name: car_wash_point_name,
        place_ru: MoykaProd?.place_ru,
        place_en: MoykaProd?.place_en
    })
    useEffect(() => {
        setData({
            carWashId: user_id ? user_id : userId,
            car_wash_point_name: car_wash_point_name,
            place_ru: MoykaProd?.place_ru,
            place_en: MoykaProd?.place_en
        })
    }, [MoykaProd, car_wash_point_name])
    const lang = cookies.get('i18next')


    const handleOpen = () => { setOpen(true); };
    const handleClose = () => { setOpen(false); };

    const onChangeHandler = event => {
        data[event.target.name] = event.target.value;
        
        setData({...data})
    }

    const changeMachineName = e => {
        e.preventDefault()
        if (!data.car_wash_point_name) {
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 3000)
        } else {
            dispatch(editMachineName(data))
            handleClose()
            navigate("/loadingPage")
        }
    }

 
    return (
        <div>
            <Button className="link" onClick={handleOpen}>+ {lang === 'am' ? 'Փոխել անվանումը' : lang === 'ru' ? 'Изменить имя' : 'Change Name'}</Button>
            <Modal open={open} onClose={handleClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
                <div className="container_modal">
                    <form onChange={onChangeHandler} onSubmit={changeMachineName} id="contact">
                        <h3>{lang === 'am' ? 'Փոխել անվանումը' : lang === 'ru' ? 'Изменить имя' : 'Change Name'}</h3>
                        <fieldset>
                            <input
                                placeholder={lang === 'am' ? 'Փոխել անվանումը' : lang === 'ru' ? 'Изменить имя' : 'Change Name'}
                                type="text"
                                tabIndex="1"
                                onChange={onChangeHandler}
                                value={data.car_wash_point_name}
                                name='car_wash_point_name'
                                required
                                autoFocus />
                        </fieldset>
                        <h3>{lang === 'am' ? 'Փոխել տեղակայման վայրը' : lang === 'ru' ? 'Изменить местоположение' : 'Change geolocation'}</h3>
                        <fieldset>
                                <input
                                placeholder={lang === 'am' ? 'Փոխել անվանումը(Ռուսերեն)' : lang === 'ru' ? 'Изменить имя(Русский)' : 'Change Name(Russian)'}
                                type="text"
                                tabIndex="1"
                                name='place_ru'
                                onChange={onChangeHandler}
                                value={data?.place_ru}
                                
                                autoFocus />
                                <input
                                placeholder={lang === 'am' ? 'Փոխել անվանումը(Անգլերեն)' : lang === 'ru' ? 'Изменить имя(Английский)' : 'Change Name(English)'}
                                type="text"
                                tabIndex="1"
                                name='place_en'
                                onChange={onChangeHandler}
                                value={data?.place_en}
                                
                                autoFocus />
                        </fieldset>
                        <fieldset>
                            <button type="submit" id="contact-submit">
                                {lang === 'am' ? 'Հաստատել' : lang === 'ru' ? 'Подтвердить' : 'Save'}
                            </button>
                        </fieldset>
                        <div onClick={handleClose} className="closeBtn">
                            <img src={closeButton} alt="close icon" />
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
}
