// styles
import "./modals.scss"


import * as React from 'react';
import { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";


// custom imports
import closeButton from '../../images/closeIcon.svg'
//import {goAddTechnician} from "../../redux/actions/addTechnicianAction";
import { goAddUser, goChangeUser } from "../../redux/actions/addUserAction";




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export default function ChangeUser({ id, name, onBut }) {
    console.log(id)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const lang = cookies.get('i18next')

    const [open, setOpen] = React.useState(false);
    const [error, setError] = useState(false)
    const [data, setData] = useState({
        id: 0,
        username: '',
        password: ''
    })

    React.useEffect(() => {
        setData({ ...data, username: name, id })
    }, [name, id])

    const handleOpen = () => {
        onBut()
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const onChangeHandler = event => {
        data[event.target.name] = event.target.value;
        setData({ ...data })
    }

    const addUserHandler = e => {
        e.preventDefault()
        if (!data.username || !data.password.trim()) {
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 3000)
        } else {
            dispatch(goChangeUser(data))
            handleClose()
        }
    }


    return (
        <div>
            <Button className="link" onClick={handleOpen}>{lang == 'am' ? 'Փոփոխել' : lang == 'ru' ? 'Изменить' : 'Update'}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >

                <div className="container_modal">
                    <form onChange={onChangeHandler} onSubmit={addUserHandler} id="contact">
                        <h3>
                            {lang == 'am' ? 'Փոփոխել' : lang == 'ru' ? 'Изменить' : 'Update'}
                        </h3>
                        <fieldset>
                            <input
                                placeholder={lang == 'am' ? 'Անուն' : lang == 'ru' ? 'имя' : 'Name'}
                                type="text"
                                tabIndex="1"
                                name='username'
                                value={data?.username}
                                required
                                autoFocus />
                        </fieldset>
                        <fieldset>
                            <input
                                placeholder={lang == 'am' ? 'Նոր գաղտնաբառ' : lang == 'ru' ? 'Новый пароль' : 'New password'}
                                type="text"
                                name='password'
                                tabIndex="3"

                                required />
                        </fieldset>
                        <fieldset>
                            <button type="submit" id="contact-submit">
                                {lang == 'am' ? 'պահպանել' : lang == 'ru' ? 'сохранить' : 'save'}
                            </button>
                        </fieldset>
                        <div onClick={handleClose} className="closeBtn">
                            <img src={closeButton} alt="close icon" />
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
}