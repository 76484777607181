//styles
import "./datatable.scss";

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


// custom imports
import Adduser from "../modals/adduser";
import AddedUsers from "../addedUsers/AddedUsers";
import { Android12Switch } from './Datatable'
import FormControlLabel from "@mui/material/FormControlLabel";
import axios from "axios";
import { API_URI, token } from "../../utils/keys";
import { useEffect } from "react";
import cookies from "js-cookie";
import { Button } from "@mui/material";
import DeleteModal from "../modals/deleteModal";
import Swal from "sweetalert2";
import ChangeUser from "../modals/changeuser";


export default function DatatableTechnician() {
    const { t } = useTranslation()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [checked, setChecked] = React.useState(false);
    const [open, setOpen] = React.useState(false)
    const [usid, setUsid] = React.useState(0)
    const [usd, setUsd] = React.useState({ id: 0, username: '' })
    const getUsers = useSelector(state => state.getUsers.users)
    const lang = cookies.get('i18next')

    // filter technician role
    let users = getUsers.filter(function (user) {
        return user.role == "user";
    });



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClick = (e, id) => {
        //Do something if checkbox is clicked
        if (!e.target.checked) {
            axios.post(`${API_URI}/user/deactivate`, {
                id
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(function (response) {
                    return "ok"
                })
                .catch(function (error) {
                    console.error(error);
                });
        } else {
            axios.post(`${API_URI}/user/activate`, {
                id
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(function (response) {
                    return "ok"
                })
                .catch(function (error) {
                    console.error(error);
                });
        }
    };
    const onDelete = async (e, id) => {
        //Do something if checkbox is clicked
        e.preventDefault()

        await axios.post(`${API_URI}/user/delete`, {
            id
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(async function (response) {
                setOpen(false)
                await Swal.fire({
                    icon: 'success',
                    text: `${lang == 'am' ? "Ձեր հարցումը կատարվել է" : lang == 'ru' ? "Ваш запрос выполнен" : "Your request has been completed"}`,
                    timer: 1500
                })
                window.location.reload(false)
            })
            .catch(async function (error) {
                setOpen(false)
                await Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",

                });
            })
    };


    useEffect(() => {
        console.clear()
    })
    console.log(usid)

    return (
        <div className="datatable">
            <div className="datatableTitle">
                {lang == 'am' ? 'Գրանցվածների ցուցակ' : lang == 'ru' ? 'Список' : 'Users list'}
                <button>
                    <Adduser />
                </button>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell width="10%" className="technics_list">{lang == 'am' ? 'Անուն' : lang == 'ru' ? 'Имя' : 'Username'}</TableCell>
                            <TableCell width="10%" className="technics_list" align="center">{lang == 'am' ? 'Թարմացնել' : lang == 'ru' ? 'Изменить' : 'Update'}</TableCell>
                            <TableCell width="20%" className="technics_list" align="center">{lang == 'am' ? 'Ժամանակ' : lang == 'ru' ? 'Время' : 'Date'}</TableCell>
                            <TableCell width="20%" className="technics_list" align="center">{lang == 'am' ? 'Ակտիվ/Ոչ Ակտիվ' : lang == 'ru' ? 'Активный/Неактивный' : 'Active/Inactive'}</TableCell>
                            <TableCell width="20%" className="technics_list" align="center">{lang == 'am' ? 'Ջնջել' : lang == 'ru' ? 'Удалить' : 'Delete'}</TableCell>
                            <TableCell width="20%" className="technics_list" align="center">{lang == 'am' ? 'Տեսնել' : lang == 'ru' ? 'Посмотреть' : 'View'}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getUsers.length == 0 ?
                            <AddedUsers /> : getUsers.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell width="25%" component="th" scope="row">
                                        <span
                                            className="technics_list_username">
                                            {row.username}</span> <br />
                                        {row.email}
                                    </TableCell>
                                    <TableCell className="technics_list_username"
                                        align="center">
                                        <ChangeUser id={usd.id} name={usd.username} onBut={() => setUsd({ id: row.id, username: row?.username })} />
                                    </TableCell>
                                    <TableCell className="technics_list_username"
                                        align="center">{row.createdAt.substring(0, 10)}</TableCell>
                                    <TableCell width="1%" align="center">
                                        <form>
                                            <FormControlLabel
                                                control={<Android12Switch
                                                    defaultChecked={row.active == true ? !checked : checked}
                                                    onClick={(e) => handleClick(e, row.id)}
                                                />}
                                            />
                                        </form>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button className="link" onClick={() => {
                                            setUsid(row.id)
                                            setOpen(true)
                                        }}>{lang == 'am' ? 'Ջնջել' : lang == 'ru' ? 'Удалить' : 'Delete'}</Button>
                                    </TableCell>
                                    <TableCell align="center">
                                        <div className="view_more_slice">
                                            <Link to={`/users/${row.id}`}>
                                                <span>
                                                    {lang == 'am' ? 'Տեսնել ավելին' : lang == 'ru' ? 'Посмотреть побольше' : 'View more'}<i className="fa-solid fa-angle-right"></i>
                                                </span>
                                            </Link>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>

            </TableContainer>
            <DeleteModal open={open} setOpen={setOpen} onDelete={(e) => onDelete(e, usid)} />
        </div>
    );
}
