import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import axios from "axios";
import { token, API_URI } from "../../utils/keys";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Disable from "../modals/disable";
import cookies from "js-cookie";
import Swal from "sweetalert2";
import ChangeSettingsBasic from "../modals/changeSettingsBasic";
import ChangeExtendedSettings from "../modals/changeExtendedSettings";
import { Fragment } from "react";
import { goUpdateDevice } from "../../redux/actions/deviceAction";
import { useDispatch } from "react-redux";
import Loader from "../../images/loader.png"

export default function DeviceTable({ idd, setIdd }) {
  const { t } = useTranslation();
  const [devices, setDevices] = useState();
  let { userId } = useParams();
  const lang = cookies.get("i18next");
  const dispatch = useDispatch()
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate()
  const [render, setRender] = useState(0)
  const [wanted, setWanted] = useState(0)
  const [wantedName, setWantedName] = useState('')
  const [secondRender, setSecondRender] = useState(0)
  useEffect(() => {
    const instance = axios.create({
      baseURL: `${API_URI}/auth/me`,
      headers: { Authorization: `Bearer ${token}` },
    });

    instance
      .get(`${API_URI}/car-wash/device`)
      .then((response) => {
        setDevices(
          response.data.filter((x) => x.car_wash_point_id === +userId)
        );
        if(secondRender === 0) {
        if(!response.data.filter((x) => x.car_wash_point_id === +userId)?.some(i => i.status === true)) {
          setSecondRender(1)
          setTimeout(() => {
            instance
      .get(`${API_URI}/car-wash/device`)
      .then((response) => {
        setDevices(
          response.data.filter((x) => x.car_wash_point_id === +userId)
        );
        setSecondRender(2)
      })
          }, 3000)
        }
      }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [userId, render]);

  const qweqwe = localStorage.getItem("bestToken");

  const [asda, adasd] = useState();

  useEffect(() => {
    axios
      .get(`https://api.zxprof.com/api/nats-lastcnf?pId=${qweqwe}`)
      .then((response) => response.data)
      .then((data) => data.dms)
      .then((val) => {
        const asd = JSON.parse(val.dataMsg);

        adasd(asd);
      });
  }, []);

  const [furion, setFurion] = useState([]);
  useEffect(() => {
    axios
      .post("https://api.zxprof.com/cmd/nats-sbr", { cmdata: qweqwe })
      .then((res) => {
        if (res.data.data == 1) {
          axios
            .get(`https://api.zxprof.com/api/nats-sbr?pId=${qweqwe}`)
            .then((res) => {
              const asdasdasd = JSON.parse(res.data?.dms?.dataMsg);
              setFurion(asdasdasd);
            });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            timer: 1500,
          });
        }
      });
  }, []);

  const [furion2, setFurion2] = useState([]);
  useEffect(() => {
    axios
      .post("https://api.zxprof.com/cmd/nats-ser", { cmdata: qweqwe })
      .then((res) => {
        if (res.data.data == 1) {
          axios
            .get(`https://api.zxprof.com/api/nats-ser?pId=${qweqwe}`)
            .then((res) => {
              if (res.data.dms?.dataMsg) {
                const asdasdasd = JSON.parse(res.data.dms?.dataMsg);
                setFurion2(asdasdasd);
              }
            });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            timer: 1500,
          });
        }
      });
  }, []);

  const handleChange = () => {
    if (checked) {
      setChecked(false);
      setIdd([]);
    } else {
      setChecked(true);
      setIdd([]);
      for (let i = 0; i < devices.length; i++) {
        if (devices?.[i].status === true) {
          setIdd((prev) => [...prev, i + 1]);
        }
      }
    }
  };

  const wantedFunc = (data) => {
    axios
      .post(`${API_URI}/car-wash/device/update`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
      .then((res) => {
        // dispatch({ type: ADD_TECHNICIAN, payload: res.data });
        setRender(prev => prev + 1)
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          timer: 1500,
        });
      });
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span style={{ color: "white" }}>
          {lang === "am" ? "սարքեր" : lang === "ru" ? "устройства" : "devices"}
        </span>
        <label
          htmlFor="chec"
          style={{
            color: "white",
            fontSize: 15,
            userSelect: "none",
            cursor: "pointer",
          }}
        >
          <input
            type={"checkbox"}
            id={"chec"}
            onChange={handleChange}
            checked={idd.length === devices?.length}
            style={{ position: "relative", top: 15 }}
          />{" "}
          {lang === "am"
            ? "Ընտրել բոլոր սարքերը"
            : lang === "ru"
              ? "Выбрать все устройства"
              : "Select all devices"}
        </label>
      </div>
      <TableContainer component={Paper} >
        <Table style={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <div>
                  {" "}
                  {lang === "am" ? "Անվանում" : lang === "ru" ? "Имя" : "Name"}
                </div>
              </TableCell>
              <TableCell align="center">
                <div>
                  {" "}
                  {lang === "am"
                    ? "Ստեղծվել է"
                    : lang === "ru"
                      ? "создано на"
                      : "createdAt"}
                </div>
              </TableCell>
              <TableCell align="center">
                <div>
                  {" "}
                  {lang === "am"
                    ? "Կարգավիճակ"
                    : lang === "ru"
                      ? "Состояние"
                      : "Status"}
                </div>
              </TableCell>
              <TableCell align="center">
                <div>
                  {" "}
                  {lang === "am"
                    ? "Կարգավորումներ"
                    : lang === "ru"
                      ? "Настройка"
                      : "Settings"}
                </div>
              </TableCell>
              <TableCell align="center">
                <div>
                  {" "}
                  {lang === 'am' ? 'Անվճար ռեժիմ' : lang === 'ru' ? 'Бесплатный режим' : 'Free mode'}
                </div>
              </TableCell>
              <TableCell align="center">
                <div>
                  {" "}
                  {lang === "am"
                    ? "տեսնել"
                    : lang === "ru"
                      ? "Посмотреть"
                      : "View"}
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {devices?.length === 0 && (
              <h2 className="not_device">
                {lang === "am"
                  ? "ավելացված սարքեր դեռևս չկան"
                  : lang === "ru"
                    ? "Устройства еще не добавлены!"
                    : "No devices added yet!"}
              </h2>
            )}

            {devices?.map((row, k) => (
              <React.Fragment key={row.id}>
                <TableRow
                  sx={
                    {
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor: idd?.includes(k + 1) ? "yellow" : row.disabled ? 'red' : 'transparent',
                    }
                  }
                  onClick={() => {
                    if (row?.status === true) {
                      if (idd?.includes(k + 1)) {
                        let asdqwe = [...idd];
                        const qweasd = idd.indexOf(k + 1);
                        asdqwe.splice(qweasd, 1);
                        setIdd(asdqwe);
                      } else {
                        setIdd((prev) => [...prev, k + 1]);
                      }
                    }
                  }}
                >
                  <TableCell component="th" scope="row" onClick={(e) => e.stopPropagation()}>
                    {wanted !== row?.id ? (
                      <div
                        onClick={(e) => e.stopPropagation()}
                        onDoubleClick={() => {
                          setWantedName(row?.name);
                          setWanted(row?.id);
                        }}
                      >
                        {row?.name}
                      </div>
                    ) : (
                      <input
                        value={wantedName}
                        style={{ width: "100%", maxWidth: "70px", color: "black", height: 25, padding: "0 2px", fontSize: 14 }}
                        autoFocus
                        onChange={(e) =>
                          e.target.value.length < 15 ? setWantedName(e.target.value) : null
                        }
                        onBlur={() => {
                          setWantedName("");
                          wantedFunc({ id: row?.id, name: wantedName })
                          setWanted(0);
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <div>{row?.createdAt.substring(0, 10)}</div>
                  </TableCell>
                  <TableCell align="center">


                   {secondRender === 1 ?  <div style={{ width: "100%", height: 15, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={Loader} className='spinnerForever' style={{ width: 15, height: 15 }} alt='' />
                                                </div> :
                    <div
                      style={{
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        gap: 6,
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: row.status ? "green" : "red",
                          width: 15,
                          height: 15,
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                      ></div>{" "}
                      <span style={{ color: "white", fontSize: 14 }}>
                        {row.status ? 'Online' : 'Offline'}
                      </span>
                    </div>}

                  </TableCell>
                  <TableCell align="center">
                    {asda?.filter((b) => b.id === k + 1).length >= 1 && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 15,
                          zIndex: 600,
                        }}
                      >
                        {row.status && furion.length >= 1 &&
                          furion.filter((z) => z.id === k + 1).length >= 1 &&


                          <ChangeSettingsBasic singleDevice={furion?.filter((z) => z.id === k + 1)?.[0]} />

                        }
                        {row.status && furion2.length >= 1 &&
                          furion2.filter((z) => z.id === k + 1).length >= 1 &&
                          furion2
                            .filter((z) => z.id === k + 1)
                            .map((x, y) =>
                              <Fragment key={y}>
                                <ChangeExtendedSettings verj={row} sf={x} deviceUser={x.id} vaha={true} />
                              </Fragment>)}
                      </div>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <div>
                      {row.Other?.freeMode

                        ? <Fragment >
                          {lang === 'am' ? 'Միացված է' : lang === 'ru' ? 'Включён' : 'enabled'}
                        </Fragment>
                        : <Fragment >{
                          lang === 'am' ? 'Անջատված է' : lang === 'ru' ? 'Выключен' : 'disabled'
                        }
                        </Fragment>
                      }
                    </div>
                  </TableCell>
                  <TableCell align="center" >
                    <div
                      className="device_link"
                      style={{ color: "red" }}
                      onClick={() => {
                        localStorage.setItem(
                          "oneDevice",
                          furion?.filter((z) => z.id === k + 1)?.[0]?.id
                        )
                        navigate(`/singleDevice/${row?.id}`)
                        // window.location.replace()
                      }
                      }
                    >
                      <div style={{ color: row.disabled ? "green" : "red" }}>
                        {lang == "am"
                          ? "Տեսնել"
                          : lang == "ru"
                            ? "Посмотреть"
                            : "View"}
                        {">"}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

