// styles
import "./ViewMachine.scss";

import { useEffect, useState, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";

// custom imports
import Navbar from "../../components/navbar/Navbar";
import axios from "axios";
import { API_URI, token } from "../../utils/keys";
// import CreateDevice from "../../components/modals/createDevice";
import { useTranslation } from "react-i18next";
import ChangeMachineName from "../../components/modals/changeMachineName";
import AllDevicesUser from "../../components/userCounterTable/UserCounerTable";
import cookies from 'js-cookie';
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { goOffFreeMode, goOnFreeMode, goResetCount } from "../../redux/actions/deviceAction";
import FreeModeflag from "../../components/modals/freeModeflag";
import UpdateData from "../../components/modals/updateData";
import ResetCounts from "../../components/modals/resetCounts";



const ViewMachine = () => {
    const { userId } = useParams();
    const [singleUser, setSingleUser] = useState()
    const [singleMachine, setSingleMachine] = useState()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const lang = cookies.get('i18next')
    const [idd, setIdd] = useState([])



    useEffect(() => {
        let one = `${API_URI}/car-wash/points/${userId}`;
        let two = `${API_URI}/car-wash/`;
        const requestOne = axios.get(one, { headers: { 'Authorization': `Bearer ${token}` } });
        const requestTwo = axios.get(two, { headers: { 'Authorization': `Bearer ${token}` } });
        axios
            .all([requestOne, requestTwo])
            .then(
                axios.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];
                    setSingleUser(responseOne?.data)
                    setSingleMachine(responseTwo?.data.filter(x => x.id == userId))

                })
            )
            .catch(errors => {
                // react on errors.
                console.error(errors);
            });

    }, [])



    return (
        <div className="new">
            <div className="newContainer">
                <Navbar />
                <div className="view_slice">
                    <div className="top_user">
                        <div>
                            <h1>{lang === 'am' ? 'Ավտոլվացման կետի անվանում' : lang === 'ru' ? 'Название автомойки' : 'Car wash Name'} - {singleMachine && singleMachine?.[0]?.car_wash_point_name}</h1>
                            <h1>{lang === 'am' ? 'Տեղակայման վայրը' : lang === 'ru' ? 'Местоположение' : 'Geolocation'} - {singleMachine && lang === 'am' ? singleMachine?.[0]?.MoykaProd?.place_am : lang === 'ru' ? singleMachine?.[0]?.MoykaProd?.place_ru : singleMachine?.[0]?.MoykaProd?.place_en}</h1>
                        </div>
                        {singleMachine && <div><ChangeMachineName car_wash_point_name={singleMachine?.[0]?.car_wash_point_name} MoykaProd={singleMachine?.[0]?.MoykaProd} /></div>}

                    </div>

                </div>
                <div style={{ display: 'flex', gap: 20 }}>
                    <span>
                        <UpdateData success={idd.length > 0} />
                    </span>
                    <span>
                        <FreeModeflag idd={idd} one={false} success={idd.length > 0} />
                    </span>
                    <span>
                        <FreeModeflag idd={idd} one={true} success={idd.length > 0} />
                    </span>
                    <span>
                        <ResetCounts idd={idd} success={idd.length > 0} />
                    </span>
                </div>
                <AllDevicesUser idd={idd} setIdd={setIdd} />
            </div>
        </div>
    );
};

export default ViewMachine;
