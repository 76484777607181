import axios from "axios";
import Swal from "sweetalert2";
import { API_URI, myUrl, token } from "../../utils/keys";
import cookies from 'js-cookie'
import {
    GET_COUNTER_USER,
    GET_DEVICE,
    GET_DEVICE_USER,
    GET_DEVICES_COUNTERS,
    GET_SINGLE_DEVICE_USER,
    GET_SETTINGS_BASIC,
    GET_SETTINGS_EXTENDED
} from "../types";
import { useNavigate } from "react-router-dom";


export const goCreateDevice = (data) => {
    return (dispatch) => {
        axios
            .post(`${API_URI}/car-wash/device/create`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            .then((res) => {
                // dispatch({ type: ADD_TECHNICIAN, payload: res.data });
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    showConfirmButton: false,
                    timer: 1500,
                });
            })
            .catch((e) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    timer: 1500,
                });
            });
    };
};
export const goUpdateDevice = (data) => {
    return (dispatch) => {
        axios
            .post(`${API_URI}/car-wash/device/update`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            .then((res) => {
                // dispatch({ type: ADD_TECHNICIAN, payload: res.data });
               
            })
            .catch((e) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    timer: 1500,
                });
            });
    };
};


export function getDevice() {
    return async dispatch => {
        const response = await axios.get(`${API_URI}/car-wash/device`, {
            headers: { 'Authorization': `Bearer ${token}` }
        })
        dispatch({
            type: GET_DEVICE,
            payload: response.data.users
        })
    }
}



export function getDevicesCounters(userId) {
    return async dispatch => {
        const response = await axios.get(`${API_URI}/counter`, {
            headers: { 'Authorization': `Bearer ${token}` }
        })

        dispatch({
            type: GET_DEVICES_COUNTERS,
            payload: response.data.filter(x => x.id == userId)[0].CarWashDevices,
            userId: userId
        })
    }
}

export function getCounter() {
    return async dispatch => {
        const response = await axios.get(`${API_URI}/counter`, {
            headers: { 'Authorization': `Bearer ${token}` }
        })

        dispatch({
            type: GET_COUNTER_USER,
            payload: response.data,
            // userId:userId
        })
    }
}


export function getDeviceUser(deviceUser) {
    return async dispatch => {
        const response = await axios.get(`${API_URI}/car-wash/device/${deviceUser}`, {
            headers: { 'Authorization': `Bearer ${token}` }
        })

        dispatch({
            type: GET_DEVICE_USER,
            payload: response.data?.Components
            // userId:userId
        })
    }
}

export function getSingleDeviceUser(deviceUser) {
    return async dispatch => {
        const response = await axios.get(`${API_URI}/car-wash/device/${deviceUser}`, {
            headers: { 'Authorization': `Bearer ${token}` }
        })
        dispatch({
            type: GET_SINGLE_DEVICE_USER,
            payload: response.data
        })
    }
}

export function settingsBasic(deviceUser) {

    return async dispatch => {
        const response = await axios.get(`${API_URI}/settings/basic`, {
            params: { token: '1970d291-392c-44b6-85cc-21fc313f589d', id: +(deviceUser) },
            headers: { 'Authorization': `Bearer ${token}` }
        })
        dispatch({
            type: GET_SETTINGS_BASIC,
            payload: response.data.filter(x => x.id === +deviceUser)
        })
    }
}

export function advancedSettingsGet(deviceUser) {

    return async dispatch => {
        const response = await axios.get(`${API_URI}/settings/extended`, {
            params: { token: '1970d291-392c-44b6-85cc-21fc313f589d' },
            headers: { 'Authorization': `Bearer ${token}` }
        })
        dispatch({
            type: GET_SETTINGS_EXTENDED,
            payload: response.data.filter(x => x.id === +deviceUser)
        })
    }
}





export const goResetCount = (cmdata) => {
    const lang = cookies.get("i18next")

    axios
        .post(`https://api.zxprof.com/cmd/nats-cr`,
            { cmdata }
        )
        .then(async (res) => {

            if (res.data.data == 1) {
                Swal.fire({
                    icon: 'success',
                    text: `${lang == 'am' ? "ձեր հաշվիչները զրոյացվել են" : lang == 'ru' ? "ваши счетчики были сброшены" : "your counters have been reset"}`,
                })
                await axios.post(`https://api.zxprof.com/cmd/nats-cnf`, { cmdata: cmdata.token })

            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    timer: 1500
                });
            }
        })
        .catch((e) => {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                timer: 1500,
            });
        });

};


export const goserviceResetConfirm = (data) => {
    const lang = cookies.get("i18next")

    return (dispatch) => {
        axios
            .put(`https://api.zxprof.com/cmd/nats-sr`,
                { cmdata: data },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            .then((res) => {
                // dispatch({ type: ADD_TECHNICIAN, payload: res.data });
                Swal.fire({
                    icon: 'success',
                    text: `${lang == 'am' ? "ձեր կարգավորումները զրոյացվել են" : lang == 'ru' ? "ваши настройки были сброшены" : "your settings have been reset"}`,
                })
            })
            .catch((e) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    timer: 1500,
                });
            });
    };
};


export const goOnFreeMode = (cmdata) => {
    const lang = cookies.get("i18next")

    axios
        .post(`https://api.zxprof.com/cmd/nats-fmon`,
            { cmdata }
        )
        .then(async (res) => {
            if (res.data.data == 1) {
                Swal.fire({
                    icon: 'success',
                    text: `${lang == 'am' ? "անվճար ռեժիմը միացված է" : lang == 'ru' ? "бесплатный режим включен" : "free mode is enabled"}`,
                })
                await axios.post('https://api.zxprof.com/cmd/nats-cnf', { cmdata: cmdata.token })

            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    timer: 1500,
                });
            }
        })
        .catch((e) => {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                timer: 1500,
            });
        });
};

export const goOffFreeMode = (cmdata) => {
    const lang = cookies.get("i18next")

    axios
        .post(`https://api.zxprof.com/cmd/nats-fmoff`,
            { cmdata }
        )
        .then(async (res) => {
            if (res.data.data == 1) {
                Swal.fire({
                    icon: 'success',
                    text: `${lang == 'am' ? "անվճար ռեժիմը անջատված է" : lang == 'ru' ? "бесплатный режим выключен" : "free mode is disabled"}`,
                })
                await axios.post('https://api.zxprof.com/cmd/nats-cnf', { cmdata: cmdata.token })

            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    timer: 1500,
                });
            }
        })
        .catch((e) => {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                timer: 1500,
            });
        });
};



export const goflagsfreeMode = (data) => {
    const lang = cookies.get("i18next")

    return (dispatch) => {
        axios
            .put(`${API_URI}/flags/freeMode`,
                { cmdata: data },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            .then((res) => {
                // dispatch({ type: ADD_TECHNICIAN, payload: res.data });
                Swal.fire({
                    icon: 'success',
                    text: `${lang == 'am' ? "ձեր կարգավորումները զրոյացվել են" : lang == 'ru' ? "ваши настройки были сброшены" : "your settings have been reset"}`,
                })
            })
            .catch((e) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    timer: 1500,
                });
            });
    };
};

export const goDisableMode = (cmdata) => {
    const lang = cookies.get("i18next")

    axios
        .post(`https://api.zxprof.com/cmd/nats-ddis`,
            { cmdata }
        )
        .then(async (res) => {
            if (res.data.data == 1) {
                Swal.fire({
                    icon: 'success',
                    text: `${lang == 'am' ? "սարքը անջատված է" : lang == 'ru' ? "Устройство выключен" : "device disabled"}`,
                })
                await axios.post('https://api.zxprof.com/cmd/nats-cnf', { cmdata: cmdata.token })

            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    timer: 1500,
                });
            }
        })
        .catch((e) => {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                timer: 1500,
            });
        });
};

export const goEnableMode = (cmdata) => {
    const lang = cookies.get("i18next")

    axios
        .post(`https://api.zxprof.com/cmd/nats-dena`,
            { cmdata }
        )
        .then(async (res) => {
            if (res.data.data == 1) {
                Swal.fire({
                    icon: 'success',
                    text: `${lang == 'am' ? "սարքը միացված է" : lang == 'ru' ? "Устройство включён" : "device enabled"}`,
                })
                await axios.post('https://api.zxprof.com/cmd/nats-cnf', { cmdata: cmdata.token })

            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    timer: 1500,
                });
            }
        })
        .catch((e) => {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                timer: 1500,
            });
        });
};

export const goDateTimeSet = (cmdata) => {
    const lang = cookies.get("i18next")

    axios
        .post(`https://api.zxprof.com/cmd/nats-dts`,
            cmdata
        )
        .then(async (res) => {

            if (res.data.data == 1) {
                Swal.fire({
                    icon: 'success',
                    text: `${lang == 'am' ? "Ամսաթվերի սահմանում" : lang == 'ru' ? "Дата и время" : "Datetime set"}`,
                })
                await axios.post(`https://api.zxprof.com/cmd/nats-cnf`, { cmdata: cmdata.token })

            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    timer: 1500
                });
            }
        })
        .catch((e) => {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                timer: 1500,
            });
        });

};
