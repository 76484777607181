// styles
import "./device.scss"


import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URI, token } from "../../utils/keys";
import AddComponents from "../modals/addComponents";
import Swal from "sweetalert2";
import cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import DeleteModal from "../modals/deleteModal";
import { getSingleDeviceUser } from "../../redux/actions/deviceAction";
// import {getSingleComponents} from "../../redux/actions/componentsAction";
// import {useDispatch} from "react-redux";

//custom imports


export default function SingleDeviceBlock({ disabled }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    let { deviceUser } = useParams();
    const dispatch = useDispatch()

    const [singleDevice, setSingleDevice] = useState()

    const lang = cookies.get('i18next')
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const getSingleDeviceUserget = useSelector(state => state.deviceReducer.getSingleDeviceUser)
    useEffect(() => { dispatch(getSingleDeviceUser(`${deviceUser}`)) }, [])

    // useEffect(() => {
    //     const instance = axios.create({
    //         baseURL: `${API_URI}/auth/me`,
    //         timeout: 1000,
    //         headers: { 'Authorization': `Bearer ${token}` }
    //     });

    //     instance.get(`${API_URI}/car-wash/device/${deviceUser}`,
    //     )
    //         .then(response => {
    //             response.data.status = true
    //             setSingleDevice(response.data)
    //         })
    //         .catch(e => {
    //             console.error(e)
    //         })

    // }, [0])


    // online_offline

    // useEffect(() => {
    //     const instance = axios.create({
    //         baseURL: `${API_URI}/auth/me`,
    //         timeout: 1000,
    //         headers: { 'Authorization': `Bearer ${token}` }
    //     });

    //     instance.get(`${API_URI}/counters`,
    //         {
    //             params: { device_id: deviceUser }, headers:
    //                 { 'Authorization': `Bearer ${token}` }
    //         })
    //         .then(response => {
    //             response.data.status = true
    //             setOnline(response.data)
    //         })
    //         .catch(e => {
    //             console.error(e)
    //         })

    // }, [0])




    const deleteDevice = async (id) => {

        try {
            await axios.post(`${API_URI}/car-wash/device/remove`, {
                "device_id": deviceUser
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(function (response) {
                    navigate('/washingMachines')
                })
                .catch(function (error) {
                    console.error(error);
                });
        }
        catch (e) {
            console.error(e, 'error')
        }

    }


    const deleteComponent = async (id) => {


        const singleComponent = getSingleDeviceUserget?.Components.filter(t => t.id === id);

        axios.post(`${API_URI}/car-wash/device/components/remove`,
            {
                component_id: singleComponent[0]?.id,
                device_id: singleComponent[0]?.device_id
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(function (response) {
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }).then(function (response) {
                axios.get(`${API_URI}/car-wash/device/${deviceUser}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then(response => {
                    setSingleDevice(response.data)
                    window.location.reload(false)
                })
                    .catch(e => {
                        console.error(e)
                    })
            })

    }


    return (
        <>
            <Card sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography sx={{ color: 'secondary.contrastText' }} component="div" variant="h5">
                            <div > {lang == 'am' ? 'Սարքի անուն' : lang == 'ru' ? 'имя устройства' : 'Device Name'} -  {getSingleDeviceUserget?.name} </div>
                        </Typography>
                        <br />

                        <Typography gutterBottom sx={{ color: 'secondary.contrastText' }} component="div">
                            {lang === 'am' ? 'Անվճար ռեժիմ' : lang === 'ru' ? 'Бесплатный режим' : 'Free mode'} - {getSingleDeviceUserget?.Other?.freeMode ? (lang === 'am' ? 'Միացված է' : lang === 'ru' ? 'Включён' : 'enabled') : (lang === 'am' ? 'Անջատված է' : lang === 'ru' ? 'Выключен' : 'disabled')}
                        </Typography>
                        <Typography gutterBottom sx={{ color: 'secondary.contrastText' }} component="div">
                            {lang === 'am' ? 'Սարք' : lang === 'ru' ? 'Устройство' : 'Device'} - {getSingleDeviceUserget?.disabled ? (lang === 'am' ? 'Միացված է' : lang === 'ru' ? 'Включён' : 'enabled') : (lang === 'am' ? 'Անջատված է' : lang === 'ru' ? 'Выключен' : 'disabled')}
                        </Typography>
                        <Typography gutterBottom variant="h5" sx={{ color: 'secondary.contrastText' }} component="div" style={{ display: 'flex', alignItems: 'center' }}>
                            {getSingleDeviceUserget?.status ? <><span style={{ backgroundColor: 'green', borderRadius: '50%', marginRight: '5px', width: '25px', height: '25px', display: 'inline-block' }}></span>Online</> : <><span style={{ backgroundColor: 'red', borderRadius: '50%', marginRight: '5px', width: '25px', height: '25px', display: 'inline-block' }}></span>Offline</>}
                        </Typography>
                    </CardContent>
                </Box>


                <div className="buttons_carwash">

                    <div className="add_components_block">
                        <AddComponents id={deviceUser} disabled={disabled} />
                    </div>

                    <button
                        onClick={() => setOpen(true)}
                        className="delete_btn">
                        <i className="fa-solid fa-trash-can">

                        </i>
                        {lang == 'am' ? 'ջնջել' : lang == 'ru' ? 'удалить' : 'delete'}
                    </button>
                </div>
                <DeleteModal open={open} setOpen={setOpen} onDelete={(e) => {
                    e.preventDefault();
                    deleteDevice(`${singleDevice?.id}`)
                }} />
            </Card> <br />




            <Card sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <div className="components_block">
                            {getSingleDeviceUserget?.Components?.length == 0 &&
                                <span style={{ color: "red" }}>
                                    {lang == 'am' ? 'Ավելացված կոմպոնենտներ դեռևս չկան!' : lang == 'ru' ? 'Компоненты еще не добавлены!' : 'No components added yet!'}
                                </span>}
                            {
                                getSingleDeviceUserget?.Components?.map((i) => {
                                    return (
                                        <div key={i.id} className="component_show" >
                                            <span >{i?.name_am}</span>
                                            <i
                                                onClick={() => { deleteComponent(i.id) }}
                                                className="fa-solid fa-delete-left"></i>
                                            <DeleteModal open={open2} setOpen={setOpen2} onDelete={() => deleteComponent(`${i?.id}`)} />
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </CardContent>
                </Box>


            </Card>
        </>
    );
}