// styles
import "./modals.scss"


import * as React from 'react';
import { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";

// custom imports
import closeButton from '../../images/closeIcon.svg'
import axios from "axios";
import { API_URI, token } from "../../utils/keys";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import cookies from 'js-cookie';




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export default function UpdateData({ success }) {
    const dispatch = useDispatch()
    const deviceUser = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [open, setOpen] = React.useState(false);
    const [error, setError] = useState(false)
    const [data, setData] = useState({
        username: '',
        password: ''
    })

    const lang = cookies.get('i18next')

    const handleOpen = async () => {
       
            setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
    };


    const addUserHandler = async (e) => {
	e.preventDefault()

        setOpen(false);

        let cmdata = localStorage.getItem("bestToken")
       
       await  axios.post(`https://api.zxprof.com/cmd/nats-cnf`, { cmdata }).then(async (response) => {
            if (response.data.data == 1) {
               await Swal.fire({
                    title: lang === 'am' ? 'Տվյալները Թարմացել են' : lang === 'ru' ? 'данные обновлены' : 'Data Updated',
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    }
                })
                navigate("/loadingPage")
            } else {
                await Swal.fire({
                    title: lang === 'am' ? 'Տվյալները Թարմացել են' : lang === 'ru' ? 'данные обновлены' : 'Data Updated',
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    }
                })
                navigate("/loadingPage")
            }
        })
            .catch(async e => {
                await Swal.fire({
                    title: lang === 'am' ? 'Տվյալները Թարմացել են' : lang === 'ru' ? 'данные обновлены' : 'Data Updated',
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    }
                })
                navigate("/loadingPage")

            })
    }


    return (
        <div>
            <Button className="link" onClick={addUserHandler}>{lang === 'am' ? 'Թարմացնել' : lang === 'ru' ? 'Обновить' : 'Update'}</Button>
            <Modal
                open={open}
                onClose={handleClose}

                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description">

                <div className="container_modal" >
                    <form onSubmit={addUserHandler} id="contact">
                        <h3>{lang === 'am' ? 'Ցանկանում ե՞ք թարմացնել տվյալները' : lang === 'ru' ? 'Хотите обновить данные?' : 'Do you want to update the data?'}</h3>

                        <fieldset>
                            <button type="submit" id="contact-submit">
                                {lang === 'am' ? 'Թարմացնել' : lang === 'ru' ? 'Обновить' : 'Update'}
                            </button>
                        </fieldset>
                        <div onClick={() => setOpen(false)} className="closeBtn">
                            <img src={closeButton} onClick={() => setOpen(false)} alt="close icon" />
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
}
