// styles
import "./washing.scss"

import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

// custom imports
import AddMachineModal from "../modals/addMachine";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URI, token } from "../../utils/keys";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cookies from 'js-cookie';
import DeleteModal from "../modals/deleteModal";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../redux/actions/getUsersAction";
import ChangeMachineName from "../modals/changeMachineName";

export default function ActionAreaCard() {
    const navigate = useNavigate()
    const [carWash, setCarWash] = useState([])
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [openId, setOpenId] = useState(null)

    let one = `${API_URI}/car-wash`;
    let two = `${API_URI}/users`;
    const users = useSelector(state => state.getUsers.users)
    const dispatch = useDispatch()
   
    const requestOne = axios.get(one, { headers: { 'Authorization': `Bearer ${token}` } });
    const requestTwo = axios.get(two, { headers: { 'Authorization': `Bearer ${token}` } });

    const lang = cookies.get('i18next')

    useEffect(() => {
        axios
            .all([requestOne])
            .then(
                axios.spread((...responses) => {
                    const responseOne = responses[0];

                    setCarWash(responseOne.data)
                    // setUsers(responseTwo.data.users)
                })
            )
            .catch(errors => {
                // react on errors.
                console.error(errors);
            });

    }, [0])

    useEffect(() => {
        dispatch(getUsers())
    }, [])

    const deleteMachine = async (e, id) => {

        e.preventDefault()

        try {
            await axios.post(`${API_URI}/car-wash/remove`, {
                'car_wash_point_id': id
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(function (response) {
                    axios.get(`${API_URI}/car-wash`,
                        { headers: { Authorization: `Bearer ${token}` } })
                        .then(function (response) {
                            setCarWash(response.data)
                        })
                    window.location.reload(false)
                })
                .catch(function (error) {
                    console.error(error);
                });
        }
        catch (e) {
            console.error(e, 'error')
        }
    }
    const onVerj = (e) => {
        localStorage.setItem("bestToken", e)
    }
	
    return (
        <>
            {loading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: '2rem', color: 'white' }}>Loading... </div> : <div>
                <div className="machines_list">
                   {lang === 'am' ? 'Ավտոլվացման կետ' : lang === 'ru' ? 'Мойка' : 'Machine list'}

                    <AddMachineModal />

                </div>

                {
                    carWash.map((info) => {
                        return (
                            <Card key={info.id}>
                                <CardActionArea>
                                    <h2 className="carwash_username">{info?.username}</h2>
                                    <CardContent>
                                        <div style={{display: 'flex', gap: 30}}>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {info?.car_wash_point_name === null ? (lang === 'am' ? 'Անանուն' : lang === 'ru' ? 'Нет имени' : 'Dont have a name') : info?.car_wash_point_name}
                                        </Typography>
                                        <ChangeMachineName car_wash_point_name={info?.car_wash_point_name} MoykaProd={info?.MoykaProd} user_id={info?.id} />
                                        </div>
                                        <Typography variant="body2" color="text.secondary.pink">
                                            {users?.find((us) => us?.id === info?.user_id)?.username}
                                        </Typography>
                                    </CardContent>
                                    
                                </CardActionArea>
                                <div className="buttons_carwash">
                                    <button onClick={() => {
					setOpen(true)
					setOpenId(info?.id)
					}} className="delete_btn"><i className="fa-solid fa-trash-can"></i>{lang === 'am' ? 'ջնջել' : lang === 'ru' ? 'удалить' : 'delete'}</button>
                                    <div onClick={async () => {
                                        setLoading(true)
                                        onVerj(info?.token)
                                        await axios.post(`https://api.zxprof.com/cmd/nats-cnf`, { cmdata: info?.token })
                                        setTimeout(() => {
                                            setLoading(false)
                                            navigate(`/viewMachine/${info.id}`)
                                        }, 2000)
                                    }}><button className="edit_btn">{lang === 'am' ? 'Տեսնել' : lang === 'ru' ? 'Посмотреть' : 'View'}</button></div>
                                </div>
                            </Card>
                        )
                    })
                }
            </div>}
	<DeleteModal open={open} setOpen={setOpen} onDelete={(e) => deleteMachine(e, `${openId}`)} />
        </>
    );
}
