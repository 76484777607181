// styles
import "./modals.scss"


import * as React from 'react';
import { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";

// custom imports
import closeButton from '../../images/closeIcon.svg'
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import cookies from 'js-cookie';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export default function DeleteModal({ open, setOpen, onDelete }) {

    const lang = cookies.get('i18next')
    const handleClose = () => {
        setOpen(false);
    };





    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}

                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description">

                <div className="container_modal" >
                    <form onSubmit={onDelete} id="contact">
                        <h3>{lang === 'am' ? 'Ջնջե՞լ' : lang === 'ru' ? 'Удалить?' : 'Delete?'}</h3>

                        <fieldset>
                            <button type="submit" id="contact-submit">
                                {lang === 'am' ? 'Ջնջել' : lang === 'ru' ? 'Удалить' : 'Delete'}
                            </button>
                        </fieldset>
                        <div onClick={() => setOpen(false)} className="closeBtn">
                            <img src={closeButton} onClick={() => setOpen(false)} alt="close icon" />
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
}