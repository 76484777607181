import axios from "axios";
import Swal from "sweetalert2";
import { API_URI, token } from "../../utils/keys";
import { GET_CHANNELS } from "../types";



export const goEditSettings = async (cmdata, data2) => {
    await axios.post(`${API_URI}/car-wash/chanel`,
        { ...data2 },
        {
            headers: { 'Authorization': `Bearer ${token}` }
        })


    await axios.post(`https://api.zxprof.com/cmd/nats-sew`,
        { cmdata }
    )
        .then(async (res) => {
	
            if (res.data.data == 1) {
		axios.post('https://api.zxprof.com/cmd/nats-ser', { cmdata: cmdata.token })
                await Swal.fire({
                    icon: "success",
                    title: "Success",
                    showConfirmButton: false,
                    timer: 1000,
                });
            } else {

                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    timer: 1000,
                });
            }
        })
        .catch((e) => {

            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                timer: 1500,
            });
        });
};


export const goEditSettingsBasic = async (cmdata) => {
    await axios.post(`https://api.zxprof.com/cmd/nats-sbw`,
        { cmdata }
    )
        .then((res) => {
	
            if (res.data.data == 1) {
		axios.post('https://api.zxprof.com/cmd/nats-sbr', { cmdata: cmdata.token })
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else {

                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    timer: 1000,
                });
            }
        })
        .catch((e) => {

            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                timer: 1500,
            });
        });
};

export const getChannels = (deviceUser) => {
    return async dispatch => {

        const response = await axios.get(`${API_URI}/car-wash/chanel?device_id=${deviceUser}`,
            {
                headers: { 'Authorization': `Bearer ${token}` }
            })
        dispatch({
            type: GET_CHANNELS,
            payload: response.data
        })
    }
}

// export const changeChannels = async (data2) => {

//     await axios.post(`${API_URI}/car-wash/chanel`,
//         { ...data2 },
//         {
//             headers: { 'Authorization': `Bearer ${token}` }
//         })


// }

