// styles
import "./WashingMachines.scss"

import React, { useEffect, useState } from 'react';
import Navbar from "../../../components/navbar/Navbar";
// import ActionAreaCard from "../../../components/washingMachines/washing";
import { CardActionArea } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
// import {Link} from "react-router-dom";
import Card from "@mui/material/Card";
import { useTranslation } from "react-i18next";
import { API_URI, token } from "../../../utils/keys";
import axios from "axios";
import { Link } from "react-router-dom";
import cookies from 'js-cookie';

// custom imports

const WashingMachinesUser = () => {
    const { t } = useTranslation()
    const [userDevice, setUserDevice] = useState()
    let one = `${API_URI}/car-wash/`;
    const [devices, setDevices] = useState()
    const lang = cookies.get('i18next')
    const [ads, adasd] = useState()
    const requestOne = axios.get(one, { headers: { 'Authorization': `Bearer ${token}` } });
    const [gumar, setGumar] = useState(0)
    const [yntacik, setYntacik] = useState(0)

    useEffect(() => {
        axios
            .all([requestOne])
            .then(
                axios.spread((...responses) => {
                    const responseOne = responses[0];
                    setUserDevice(responseOne.data)
                    // setCarWash(responseOne.data)
                })
            )
            .catch(errors => {
                // react on errors.
                console.error(errors);
            });

    }, [0])
    useEffect(() => {
        const instance = axios.create({
            baseURL: `${API_URI}/auth/me`,
            headers: { 'Authorization': `Bearer ${token}` }
        });

        instance.get(`${API_URI}/car-wash/device`)
            .then(response => {

                setDevices(response.data)
            })
            .catch(e => {
                console.error(e)
            })

    }, [])

    const onVerj = (e) => {
        localStorage.setItem("bestToken", e)
    }

    useEffect(() => {
        if (userDevice) {
            setGumar(0)
            setYntacik(0)
            axios.get(`https://api.zxprof.com/api/nats-lastcnf?pId=${userDevice?.[0].token}`)
                .then((response) => response.data)
                .then((data) => data.dms)
                .then((val) => {


                    const asd = JSON.parse(val.dataMsg)



                    adasd(asd)
                    for (let i = 0; i < asd.length; i++) {
                        setGumar(prev => prev + asd[i].bill.t + asd[i].cashless.t + asd[i].coin.t)
                        setYntacik(prev => prev + asd[i].bill.d + asd[i].cashless.d + asd[i].coin.d)
                    }
                })
        }
    }, [userDevice])

    return (
        <div className="washing_machines_section_user">
            <div className="washing_machines_container">
                <Navbar />
                {
                    userDevice && userDevice.length == 0 && <div className="not_added_car_wash">{lang === 'am' ? 'ձեր համար ավտոլվացման կետեր ստեղծված չեն' : lang === 'ru' ? 'Для вас нет автомоек' : 'There are no car wash points for you'}</div>
                }
                <div className="washing_machines_slice">
                    {
                        userDevice?.map((info) => {
                            return (
                                <Card key={info?.id}>
                                    <CardActionArea>
                                        <h2 className="carwash_username">{lang === 'am' ? 'Ավտոլվացման կետի անվանում' : lang === 'ru' ? 'Название автомойки' : 'Car wash Name'} -
                                            {info?.car_wash_point_name === null ? (lang === 'am' ? 'Անվանում դեռ ավելացված չէ' : lang === 'ru' ? 'Имя еще не добавлено' : 'Name not added yet') : info?.car_wash_point_name} </h2>
                                        <CardContent>
                                            <Typography variant="body2" color="text.secondary.pink">
                                                {lang === 'am' ? 'Սարքեր' : lang === 'ru' ? 'Устройство' : 'Devices'} - {devices?.length}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary.pink">
                                                {lang === 'am' ? 'Գումարի ընդհանուր չափ' : lang === 'ru' ? 'Общая сумма денег' : 'Total amount of money'}  - {gumar} {lang === 'am' ? 'դրամ' : lang === 'ru' ? 'монет' : 'coin'}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary.pink">
                                                {lang === 'am' ? 'Ընթացիկ դրամարկղ' : lang === 'ru' ? 'Текущая касса' : 'Current cash register'} - {yntacik} {lang === 'am' ? 'դրամ' : lang === 'ru' ? 'монет' : 'coin'}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary.pink">
                                                {lang === 'am' ? 'Ստեղծվել է' : lang === 'ru' ? 'Создано на' : 'createdAt'} - {info?.createdAt?.substring(0, 10)}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <div className="buttons_carwash_user">
                                        <Link to={`/viewMachineUser/${info?.id}`} onClick={() => onVerj(info?.token)}><button className="edit_btn">{lang === 'am' ? 'Տեսնել' : lang === 'ru' ? 'Посмотреть' : 'View'}</button></Link>
                                    </div>
                                </Card>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default WashingMachinesUser;
