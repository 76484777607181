// styles
import "./finishChange.scss"


import { useTranslation } from "react-i18next";

// custom import
import Navbar from "../../components/navbar/Navbar";
import cookies from 'js-cookie';


const SendCode = () => {
    const { t } = useTranslation()

    const lang = cookies.get('i18next')

    return (
        <div className='cangeDiv'>
            <Navbar />
            <div className="mainDiv">
                <div className="cardStyle">
                    <form name="signupForm" id="signupForm">
                        <h2 className="form_title">
                            {lang === 'am' ? 'փոխել գաղտնաբառը' : lang === 'ru' ? 'изменить пароль' : 'change password'}
                        </h2>
                        <div className="inputDiv">
                            <label className="inputLabel" htmlFor="password">{lang === 'am' ? 'Նոր գաղտաբառ' : lang === 'ru' ? 'Новый пароль' : 'New password'}</label>
                            <input placeholder={lang === 'am' ? 'Նոր գաղտաբառ' : lang === 'ru' ? 'Новый пароль' : 'New password'} type="password" name="password" required />
                        </div>

                        <div className="inputDiv">
                            <label className="inputLabel" htmlFor="password">{lang === 'am' ? 'Մուտքագրեք կոդը' : lang === 'ru' ? 'Введите код' : 'Please Check Code'}</label>
                            <input placeholder={lang === 'am' ? 'Մուտքագրեք կոդը' : lang === 'ru' ? 'Введите код' : 'Please Check Code'} type="text" name="enterCode" required />
                        </div>

                        <div className="buttonWrapper">
                            <button type="submit" id="submitButtonCode"
                                className="submitButton pure-button pure-button-primary">
                                <span>{lang === 'am' ? 'Հաստատել' : lang === 'ru' ? 'Подтвердить' : 'Save'}</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SendCode