//styles
import "./DeviceUser.scss"

import React, { Fragment, useEffect, useState } from 'react';
import Navbar from "../../components/navbar/Navbar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
//mui
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {
    getCounter,
    getDeviceUser,
    getSingleDeviceUser, goflagsfreeMode,
    goResetCount,
    goserviceResetConfirm, settingsBasic, advancedSettingsGet
} from "../../redux/actions/deviceAction";
import EditCounts from "../../components/modals/editCounts";
import FreeMode from "../../components/modals/freeMode";
import UpdateData from "../../components/modals/updateData";
import FreeModeflag from "../../components/modals/freeModeflag";
import ChangeSettingsBasic from "../../components/modals/changeSettingsBasic";
import ChangeExtendedSettings from "../../components/modals/changeExtendedSettings";
import PagePreLoader from "../../components/PagePreLoader/PagePreLoader";
import DeviceUserGetCounters from "./DeviceUserGetCounters";
import cookies from "js-cookie";
import axios from "axios";
import Swal from "sweetalert2";
import ResetCounts from "../../components/modals/resetCounts";
import { getChannels } from "../../redux/actions/settingsAction";
import { getComponents } from "../../redux/actions/componentsAction";

const DeviceUser = ({ setDisabled }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const lang = cookies.get("i18next")
    const navigate = useNavigate()
    const { deviceUser } = useParams()
    // const [stop, setStop] = useState(false)
    const [view, setView] = useState(1)
    const [storageRole, setStorageRole] = useState(false)
    const [one, setOne] = useState()
    const [sf, setSf] = useState()
    const components = useSelector(state => state.componentsReducer.components)
    useEffect(() => {
        dispatch(getComponents())
    }, [])
    const getSingleDeviceUserget = useSelector(state => state.deviceReducer.getSingleDeviceUser)
    const [a, setA] = useState(1)
    const qweqwe = localStorage.getItem("bestToken")
    const iddevice = +localStorage.getItem("oneDevice")
    const [last, setLast] = useState()
    let simpleRole = localStorage.getItem('roleId')
    const channels = useSelector(state => state.componentsReducer.channels)
    useEffect(() => {
        dispatch(getChannels(+deviceUser))
    }, [deviceUser])
    const [arr, setArr] = useState([])
    const [asdasd, setAsdasd] = useState()
    useEffect(() => {
        dispatch(getDeviceUser(`${deviceUser}`))
        dispatch(getSingleDeviceUser(`${deviceUser}`))
        // dispatch(settingsBasic(`${deviceUser}`))

    }, [])

    // useEffect(() => {
    //     dispatch(advancedSettingsGet(`${deviceUser}`))
    // }, [])
    //nats-lastcnf



    const [furion, setFurion] = useState()
    const funcset = async () => {
        await axios.post('https://api.zxprof.com/cmd/nats-sbr', { cmdata: qweqwe })
            .then((res) => {
                if (res.data.data == 1) {
                    axios.get(`https://api.zxprof.com/api/nats-sbr?pId=${qweqwe}`)
                        .then((res) => {
                            const asdasdasd = JSON.parse(res.data.dms.dataMsg)
                            setFurion(asdasdasd?.[asdasdasd?.findIndex((i) => +i.id === +iddevice)])
                        })
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        timer: 1500,
                    });
                }
            })
    }
    const funcexset = async () => {

        await axios.post('https://api.zxprof.com/cmd/nats-ser', { cmdata: qweqwe })
            .then((res) => {
                if (res.data.data == 1) {
                    axios.get(`https://api.zxprof.com/api/nats-ser?pId=${qweqwe}`)
                        .then((res) => {
                            const asdasdasd = JSON.parse(res.data.dms.dataMsg)
                            setSf(asdasdasd?.[asdasdasd?.findIndex((i) => +i.id === +iddevice)])
                        })
                } else {

                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        timer: 1500,
                    });
                }
            })
    }

    useEffect(() => {
        setStorageRole(simpleRole)
    }, [simpleRole])
    useEffect(() => {
        if (getSingleDeviceUserget?.Counter?.chSpent) {
            JSON.parse(getSingleDeviceUserget?.Counter?.chSpent)?.forEach((i, k) => {
                setArr(prev => [...prev, { indexnum: k, comp_id: null, id: 0 }])
                setA(2)
            })
        }
    }, [getSingleDeviceUserget])
    useEffect(() => {
        if (setDisabled) {
            setDisabled(asdasd?.twoFlag)
        }
    }, [asdasd, storageRole])
    useEffect(() => {
        if ((a === 2 && channels.length > 0)) {
            channels?.forEach((i) => {

                arr.filter((z) => z.indexnum == i.indexnum).map((z) => {
                    z.comp_id = i.comp_id;
                    z.id = i.id
                })
                setArr([...arr])
            })
        }
    }, [a, channels])


    return (
        <div className="device_user_section">

            {storageRole === "3" && <> <Navbar /> </>}


            <div className="device_user_section_slice">

                {storageRole === "3" && <>  <div className="update_data">
                    <UpdateData success={true} />
                </div>
                    <br />
                    <div className="free_mode_fleg">
                        <FreeModeflag idd={iddevice} one={getSingleDeviceUserget?.Other?.freeMode} success={true} />
                    </div>
                    <br />
                    {getSingleDeviceUserget && <DeviceUserGetCounters getSingleDeviceUserget={getSingleDeviceUserget} oneFlag={getSingleDeviceUserget?.Counter?.oneFlag}
                        twoFlag={getSingleDeviceUserget?.Counter?.disabled} />}
                    <br />
                </>}
                {/* {storageRole && storageRole == 3 &&
                    <>
                        <Card sx={{ minWidth: 345 }}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    <h4 className="components_name" >
                                        {lang === 'am' ? 'Կոմպոնենտներ' : lang === 'ru' ? 'Компоненты' : 'Components'}
                                    </h4>
                                    <div className="componenets_slice">
                                        {
                                            getDeviceUserComponents !== null && getDeviceUserComponents && getDeviceUserComponents.map((i) => {
                                                return (
                                                    <div key={i?.id} className="single_components">
                                                        <span >
                                                            {lang === "en" && i?.name_en}
                                                            {lang === "am" && i?.name_am}   
                                                            {lang === "ru" && i?.name_ru}
                                                        </span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Typography>
                            </CardContent>
                        </Card><br /><br />
                    </>} */}


                <div className="buttons_route" style={{ marginBottom: 10 }} >
                    <button onClick={() => setView(1)}
                        className={view === 1 ? 'active_button' : 'counters_all'}>{lang === 'am' ? 'Հաշվիչ' : lang === 'ru' ? 'Прилавок' : 'Counter'}
                    </button>
                    {getSingleDeviceUserget ?
                        <>
                            <button
                                onClick={() => {
                                    setView(2)
                                    funcset()
                                }}
                                className={view === 2 ? 'active_button' : 'settings_all'} >{lang === 'am' ? 'Հիմնական կարգավորումներ' : lang === 'ru' ? 'Главные настройки' : 'Basic settings'}
                            </button>

                            {storageRole && storageRole == 2 && < button
                                onClick={() => {
                                    setView(3)
                                    funcexset()
                                }}

                                className={view === 3 ? 'active_button' : 'advanced_settings'} >{lang === 'am' ? 'Ընդլայնված կարգավորումներ' : lang === 'ru' ? 'Расширенные настройки' : 'Advanced settings'}
                            </button>}
                        </> : <span className="settings_not" >{lang === 'am' ? 'կարգավորումները դեռևս չեն եկել,խնդրում ենք սպասել կամ թարմացնել էջը' : lang === 'ru' ? 'настройки еще не пришли, подождите или обновите страницу' : 'the settings have not arrived yet, please wait or refresh the page'}</span>
                    }


                </div>
                {/*table start*/}

                {
                    view === 1 && <> <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '50%', textAlign: "center", }}><div >{lang === 'am' ? 'Հաշվիչի անվանում' : lang === 'ru' ? 'Имя прилавок' : 'Counter name'}</div></TableCell>
                                    <TableCell style={{ width: '50%', textAlign: "center", }}><div >{lang === 'am' ? 'Արժեք' : lang === 'ru' ? 'Ценность прилавок' : 'Counter value'}</div></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/*counter*/}
                                {/*{last && Object.keys(last).map((i) => {*/}
                                {/*    return (*/}
                                {/*        <TableRow key={i?.id}>*/}
                                {/*            <TableCell  component="th" scope="row">*/}
                                {/*                {i}*/}
                                {/*            </TableCell>*/}
                                {/*            <TableCell align="right">{last[i]}</TableCell>*/}
                                {/*        </TableRow>}*/}
                                {/*    )*/}
                                {/*})}*/}

                                <TableRow>
                                    <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Մետաղադրամների հաշվիչ' : lang === 'ru' ? 'Счетчик монет' : 'Coin counter'} <br />{lang === 'am' ? 'Մետաղադրամների հաշվիչ(զրոյացվող)' : lang === 'ru' ? 'Счетчик монет(сбрасываемый)' : 'Coin counter(resettable)'}</div></TableCell>
                                    <TableCell align="right"><div > {getSingleDeviceUserget?.Counter?.coinT} <br />{getSingleDeviceUserget?.Counter?.coinD}</div></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Թղթադրամների հաշվիչ' : lang === 'ru' ? 'Счетчик монет' : 'Bill Counter'} <br />{lang === 'am' ? 'Թղթադրամների հաշվիչ(զրոյացվող)' : lang === 'ru' ? 'Счетчик монет(сбрасываемый)' : 'Bill Counter(resettable)'}</div></TableCell>
                                    <TableCell align="right"><div >{getSingleDeviceUserget?.Counter?.billT} <br />{getSingleDeviceUserget?.Counter?.billD}</div></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Անկանխիկ վճարումներիի հաշվիչ' : lang === 'ru' ? 'Калькулятор безналичной оплаты' : 'Cashless Payments Counter'} <br />{lang === 'am' ? 'Անկանխիկ վճարումներիի հաշվիչ(զրոյացվող)' : lang === 'ru' ? 'Калькулятор безналичной оплаты(сбрасываемый)' : 'Cashless Payments Counter(resettable)'}</div></TableCell>
                                    <TableCell align="right"><div >{getSingleDeviceUserget?.Counter?.cashlessT} <br />{getSingleDeviceUserget?.Counter?.cashlessD}</div></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Բոնուսների հաշվիչ' : lang === 'ru' ? 'Бонусный калькулятор' : 'Bonus Counter'} <br />{lang === 'am' ? 'Բոնուսների հաշվիչ(զրոյացվող)' : lang === 'ru' ? 'Бонусный калькулятор(сбрасываемый)' : 'Bonus Counter(resettable)'}</div></TableCell>
                                    <TableCell align="right"><div >{getSingleDeviceUserget?.Counter?.bonusT} <br />{getSingleDeviceUserget?.Counter?.bonusD}</div></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Սպասարկման ժամանակացույցի հաշվիչ' : lang === 'ru' ? 'Калькулятор графика обслуживания' : 'Maintenance schedule calculator'} <br />{lang === 'am' ? 'Սպասարկման ժամանակացույցի հաշվիչ(զրոյացվող)' : lang === 'ru' ? 'Калькулятор графика обслуживания(сбрасываемый)' : 'Maintenance schedule calculator(resettable)'}</div></TableCell>
                                    <TableCell align="right"><div >{getSingleDeviceUserget?.Counter?.serviceT} <br />{getSingleDeviceUserget?.Counter?.serviceD}</div></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Սարքի ընդհանուր աշխատաց ժամանակը(1 ժամ քայլով)' : lang === 'ru' ? 'Общее время работы устройства (с шагом 1 час)' : 'Total working time of the device (in 1 hour increments)'}</div></TableCell>
                                    <TableCell align="right"><div >{getSingleDeviceUserget?.Counter?.powerOnTime}</div></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Յուրաքանչյուր կանալով աշխատած գումարի չափը' : lang === 'ru' ? 'Счетчики потраченных каналов' : 'Channels Spent Counters'}</div></TableCell>
                                    <table className="maintable">
                                        <thead>
                                            <tr>
                                                <th>{lang === 'am' ? 'Համար' : lang === 'ru' ? 'Номер' : 'Number'}</th>
                                                <th>{lang === 'am' ? 'Անուն' : lang === 'ru' ? 'Название' : 'Name'}</th>
                                                <th>{lang === 'am' ? 'Գումար' : lang === 'ru' ? 'Деньги' : 'Money'}</th>
                                                <th>{lang === 'am' ? 'Աշխատանքային ժամերը վճարովի ռեժիմում' : lang === 'ru' ? 'Время работы в платном режиме' : 'Working hours in paid mode'}</th>
                                                <th>{lang === 'am' ? 'Աշխատանքային ժամանակը անվճար ռեժիմում' : lang === 'ru' ? 'Время работы в бесплатном режиме' : 'Working time in free mode'}</th>
                                            </tr>
                                        </thead>

                                        {getSingleDeviceUserget?.Counter?.chSpent && JSON.parse(getSingleDeviceUserget?.Counter?.chSpent).length > 0 && <tbody>{JSON.parse(getSingleDeviceUserget?.Counter?.chSpent)?.map((i, k) => (
                                            <tr key={k}>


                                                <td> {k + 1}</td>

                                                <td>  {lang === 'am' ? getSingleDeviceUserget?.Components?.filter(c => c.id === arr?.[k]?.comp_id)?.[0]?.name_am : lang === 'ru' ? getSingleDeviceUserget?.Components?.filter(c => c.id === arr?.[k]?.comp_id)?.[0]?.name_ru : getSingleDeviceUserget?.Components?.filter(c => c.id === arr?.[k]?.comp_id)?.[0]?.name_en}</td>
                                                <td><tr style={{ display: 'flex', flexDirection: 'column' }}><td> {i?.t} </td><td>{i?.d}</td></tr></td>
                                                <td><tr style={{ display: 'flex', flexDirection: 'column' }}><td> {JSON.parse(getSingleDeviceUserget?.Counter?.chTimePaidMode)?.[k]?.t / 0.1} </td><td>{JSON.parse(getSingleDeviceUserget?.Counter?.chTimePaidMode)?.[k]?.d / 0.1}</td></tr></td>

                                                <td><tr style={{ display: 'flex', flexDirection: 'column' }}><td> {JSON.parse(getSingleDeviceUserget?.Counter?.chTimeFreeMode)?.[k]?.t} </td><td> {JSON.parse(getSingleDeviceUserget?.Counter?.chTimeFreeMode)?.[k]?.d} </td></tr></td>


                                            </tr>
                                        ))}</tbody>}
                                    </table>
                                </TableRow>
                                {/* <TableRow>
                                    <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Յուրաքանչյուր կանալով աշխատած ժամանակը (0․1 վարկյան քայլով)' : lang === 'ru' ? 'Время, затраченное на каждый канал (с шагом 0,1 кредита)' : 'Channels Time Counters, Paid Mode'} </div></TableCell>
                                    <TableCell align="right"> <div style={{display: 'flex', flexDirection: 'column'}}>{last?.chTimePaidMode?.map((i, k) => (
                                        <Fragment key={k}>
                                            <div >{i?.t} <br />{i?.d}</div>
                                        </Fragment>
                                    ))}</div></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Յուրաքանչյուր կանալով աշխատած ժամանակը անվճար ռեժիմում(0․1 վարկյան քայլով)' : lang === 'ru' ? 'Время, проведенное на каждом канале в бесплатном режиме (с шагом 0,1 балла)' : 'Time spent on each channel in free mode (in steps of 0.1 credit)'}</div></TableCell>
                                    {last?.chTimeFreeMode?.map((i, k) => (
                                        <Fragment key={k}>
                                            <TableCell align="right"><div >{i?.t} <br />{i?.d}</div></TableCell>
                                        </Fragment>
                                    ))}

                                </TableRow> */}


                            </TableBody>
                        </Table>
                    </TableContainer>
                        <br />
                        {getSingleDeviceUserget?.status && <span style={{ margin: '0 20px' }}>
                            <ResetCounts idd={[iddevice]} success={true} />
                        </span>}

                        {/*table end*/}

                    </>
                }
                {/*table start*/}
                {
                    view === 2 && <>
                        <TableContainer component={Paper}>
                            <div style={{ color: "green", fontSize: "18px", fontWeight: "600" }}><div >{lang === 'am' ? 'Հիմնական կարգավորումներ' : lang === 'ru' ? 'Главные настройки' : 'Basic settings'}</div></div>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{
                                            width: '50%',
                                            textAlign: "center",
                                            fontSize: "18px"
                                        }}><div >{lang === 'am' ? 'Կարգավորումների անվանում' : lang === 'ru' ? 'Имя Настройки' : 'Settings name'}</div></TableCell>
                                        <TableCell style={{
                                            width: '50%',
                                            textAlign: "center",
                                            fontSize: "18px"
                                        }}><div >{lang === 'am' ? 'Արժեք' : lang === 'ru' ? 'Ценность' : 'Settings value'}</div></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(getSingleDeviceUserget?.Other?.tariff || getSingleDeviceUserget?.Other?.tariff === 0) && JSON.parse(getSingleDeviceUserget?.Other?.tariff).length > 0 && <TableRow>
                                        <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Սակագին' : lang === 'ru' ? 'Тариф' : 'Tariff'}</div></TableCell>
                                        <TableCell align="right"><div >{JSON.parse(getSingleDeviceUserget?.Other?.tariff)?.map((i, k) => (
                                            <span key={k} style={{ marginRight: 10 }}>
                                                {k + 1}:{i}
                                            </span>
                                        ))}</div></TableCell>
                                    </TableRow>}
                                    {(getSingleDeviceUserget?.Other?.pauseTime || getSingleDeviceUserget?.Other?.pauseTime === 0) && <TableRow>
                                        <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Դադարի ժամանակը' : lang === 'ru' ? 'Время паузы' : 'Pause time'}</div></TableCell>
                                        <TableCell align="right"><div >{getSingleDeviceUserget?.Other?.pauseTime}</div></TableCell>
                                    </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.coinNominal || getSingleDeviceUserget?.DeviceSetting?.coinNominal === 0) && <TableRow>
                                        <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Մետաղադրամի նոմինալ' : lang === 'ru' ? 'Номинал монеты' : 'Coin Nominal'}</div></TableCell>
                                        <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.coinNominal}</div></TableCell>
                                    </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.billNominal || getSingleDeviceUserget?.DeviceSetting?.billNominal === 0) && <TableRow>
                                        <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Թղթադրամի նոմինալ' : lang === 'ru' ? 'Номинальный счет' : 'Bill Nominal'}</div></TableCell>
                                        <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.billNominal}</div></TableCell>
                                    </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.bonusPct || getSingleDeviceUserget?.DeviceSetting?.bonusPct === 0) && <TableRow>
                                        <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Բոնուսի տոկոս' : lang === 'ru' ? 'Бонусный процент' : 'Bonus percent'} <br /> {lang === 'am' ? 'Բոնուսի շեմ' : lang === 'ru' ? 'Бонусный порог' : 'Bonus Value'}</div></TableCell>
                                        <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.bonusPct} <br />{getSingleDeviceUserget?.DeviceSetting?.bonusVal}</div></TableCell>
                                    </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.bingoThr || getSingleDeviceUserget?.DeviceSetting?.bingoThr === 0) && <TableRow>
                                        <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Բինգոի շեմ' : lang === 'ru' ? 'Порог призовой суммы' : 'Bingo Threshold'} <br /> {lang === 'am' ? 'Բինգոի չափ' : lang === 'ru' ? 'Призовая сумма' : 'Bingo Value'}</div></TableCell>
                                        <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.bingoThr} <br /> {getSingleDeviceUserget?.DeviceSetting?.bingoVal}</div></TableCell>
                                    </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.tariffSchStart || getSingleDeviceUserget?.DeviceSetting?.tariffSchStart === 0) && <TableRow>
                                        <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Գիշերային ռեժիմով տարիֆների սկսելու ժամը' : lang === 'ru' ? 'Время начала действия ночных тарифов' : 'Scheduled tariffs start time'}</div></TableCell>
                                        <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.tariffSchStart}</div></TableCell>
                                    </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.tariffSchEnd || getSingleDeviceUserget?.DeviceSetting?.tariffSchEnd === 0) && <TableRow>
                                        <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Գիշերային ռեժիմով տարիֆների ավարտելու ժամը' : lang === 'ru' ? 'Время окончания действия запланированных тарифов' : 'Scheduled tariffs end time'}</div></TableCell>
                                        <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.tariffSchEnd}</div></TableCell>
                                    </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.tariffPct || getSingleDeviceUserget?.DeviceSetting?.tariffPct === 0) && <TableRow>
                                        <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Գիշերային ռեժիմով տարիֆների նվազեցման տոկոսը' : lang === 'ru' ? 'Процент снижения скорости для ночного режима' : 'Scheduled tariffs percent'}</div></TableCell>
                                        <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.tariffPct}</div></TableCell>
                                    </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.bonusSchStart || getSingleDeviceUserget?.DeviceSetting?.bonusSchStart === 0) && <TableRow>
                                        <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Գիշերային ռեժիմով բոնուսի սկսելու ժամը։' : lang === 'ru' ? 'Время начала бонуса ночного режима' : 'Scheduled bonus start time'}</div></TableCell>
                                        <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.bonusSchStart}</div></TableCell>
                                    </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.bonusSchEnd || getSingleDeviceUserget?.DeviceSetting?.bonusSchEnd === 0) && <TableRow>
                                        <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Գիշերային ռեժիմով բոնուսի ավարտելու ժամը' : lang === 'ru' ? '"Время окончания бонуса ночного режима' : 'Scheduled bonus end time'}</div></TableCell>
                                        <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.bonusSchEnd}</div></TableCell>
                                    </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.bonusPct || getSingleDeviceUserget?.DeviceSetting?.bonusPct === 0) && <TableRow>
                                        <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Գիշերային ռեժիմով բոնուսի տոկոսը' : lang === 'ru' ? 'Бонусный процент в ночном режиме' : 'Scheduled Bonus Percent'}</div></TableCell>
                                        <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.bonusPct}</div></TableCell>
                                    </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.bonusVal || getSingleDeviceUserget?.DeviceSetting?.bonusVal === 0) && <TableRow>
                                        <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Գիշերային ռեժիմով բոնուսի շեմը' : lang === 'ru' ? 'Бонусный порог ночного режима' : 'Scheduled Bonus Value'}</div></TableCell>
                                        <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.bonusVal}</div></TableCell>
                                    </TableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br /><br />
                        {/* <form onSubmit={serviceResetConfirmHandler}>
                            <button type="submit" className="reset_counts">{lang === 'am' ? 'զրոյացնել կարգավորումները' : lang === 'ru' ? 'онулировать натройки' : 'reset settings'}</button>
                        </form> */}

                        {getSingleDeviceUserget?.status && furion && <ChangeSettingsBasic singleDevice={furion} />}

                    </>
                }

                {/*table end*/}

                {
                    view === 3 && storageRole && storageRole == 2 && <>
                        <TableContainer component={Paper}>
                            <div style={{ color: "green", fontSize: "18px", fontWeight: "600" }}><div >{lang === 'am' ? 'Ընդլայնված կարգավորումներ' : lang === 'ru' ? 'Расширенные настройки' : 'Advanced settings'}</div></div>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{
                                            width: '50%',
                                            textAlign: "center",
                                            fontSize: "18px"
                                        }}><div >{lang === 'am' ? 'Կարգավորումների անվանում' : lang === 'ru' ? 'Имя Настройки' : 'Settings name'}</div></TableCell>
                                        <TableCell style={{
                                            width: '50%',
                                            textAlign: "center",
                                            fontSize: "18px"
                                        }}><div >{lang === 'am' ? 'Արժեք' : lang === 'ru' ? 'Ценность' : 'Settings value'}</div></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(getSingleDeviceUserget?.DeviceSetting?.mode || getSingleDeviceUserget?.DeviceSetting?.mode === 0) &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Ռեժիմ' : lang === 'ru' ? 'Режим' : 'Mode'}</div></TableCell>
                                            <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.mode}</div></TableCell>
                                        </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.bypass || getSingleDeviceUserget?.DeviceSetting?.bypass === 0) &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >bpEn</div></TableCell>
                                            <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.bypass}</div></TableCell>
                                        </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.bpTime || getSingleDeviceUserget?.DeviceSetting?.bpTime === 0) &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >bpTime</div></TableCell>
                                            <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.bpTime}</div></TableCell>
                                        </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.bypassChann || getSingleDeviceUserget?.DeviceSetting?.bypassChann === 0) && JSON.parse(getSingleDeviceUserget?.DeviceSetting?.bypassChann).length > 0 &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >bpCh</div></TableCell>
                                            <TableCell align="right"><div >{JSON.parse(getSingleDeviceUserget?.DeviceSetting?.bypassChann)?.map((i, k) => (
                                                <span key={k} style={{ marginRight: 10 }}>
                                                    {k + 1}:{i}
                                                </span>
                                            ))}</div></TableCell>
                                        </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.service || getSingleDeviceUserget?.DeviceSetting?.service === 0) &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Սերվիս' : lang === 'ru' ? 'Сервис' : 'Service'}</div></TableCell>
                                            <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.service}</div></TableCell>
                                        </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.hopper || getSingleDeviceUserget?.DeviceSetting?.hopper === 0) &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Միացված է' : lang === 'ru' ? 'Включено' : 'Enabled'}</div></TableCell>
                                            <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.hopper}</div></TableCell>
                                        </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.hopperNominal || getSingleDeviceUserget?.DeviceSetting?.hopperNominal === 0) &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Անվանական' : lang === 'ru' ? 'Номинальный' : 'Nominal'}</div></TableCell>
                                            <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.hopperNominal}</div></TableCell>
                                        </TableRow>}
                                    {(getSingleDeviceUserget?.Other?.hopperTimeout || getSingleDeviceUserget?.Other?.hopperTimeout === 0) &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Ընդմիջում' : lang === 'ru' ? 'Тайм-аут' : 'Timeout'}</div></TableCell>
                                            <TableCell align="right"><div >{getSingleDeviceUserget?.Other?.hopperTimeout}</div></TableCell>
                                        </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.hopperThreshold || getSingleDeviceUserget?.DeviceSetting?.hopperThreshold === 0) &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >{lang === 'am' ? 'շեմ' : lang === 'ru' ? 'Порог' : 'Threshold'}</div></TableCell>
                                            <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.hopperThreshold}</div></TableCell>
                                        </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.valveP || getSingleDeviceUserget?.DeviceSetting?.valveP === 0) &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Ժամանակաշրջան' : lang === 'ru' ? 'Период' : 'Period'}</div></TableCell>
                                            <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.valveP}</div></TableCell>
                                        </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.ValveD || getSingleDeviceUserget?.DeviceSetting?.ValveD === 0) &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Տևողությունը' : lang === 'ru' ? 'Продолжительность' : 'Duration'}</div></TableCell>
                                            <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.ValveD}</div></TableCell>
                                        </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.ValveC || getSingleDeviceUserget?.DeviceSetting?.ValveC === 0) &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Հաշվել' : lang === 'ru' ? 'Считать' : 'Count'}</div></TableCell>
                                            <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.ValveC}</div></TableCell>
                                        </TableRow>}
                                    {(getSingleDeviceUserget?.Other?.tickerEnabled || getSingleDeviceUserget?.Other?.tickerEnabled === 0) &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Ticker-ը միացված է' : lang === 'ru' ? 'Тикер включен' : 'Ticker enabled'}</div></TableCell>
                                            <TableCell align="right"><div >{getSingleDeviceUserget?.Other?.tickerEnabled}</div></TableCell>
                                        </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.currency || getSingleDeviceUserget?.DeviceSetting?.currency === 0) &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Արժույթ' : lang === 'ru' ? 'Валюта' : 'Currency'}</div></TableCell>
                                            <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.currency}</div></TableCell>
                                        </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.digColor || getSingleDeviceUserget?.DeviceSetting?.digColor === 0) &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >dColor</div></TableCell>
                                            <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.digColor}</div></TableCell>
                                        </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.color || getSingleDeviceUserget?.DeviceSetting?.color === 0) && JSON.parse(getSingleDeviceUserget?.DeviceSetting?.colors).length > 0 &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Գույն' : lang === 'ru' ? 'Цвет' : 'Color'}</div></TableCell>
                                            <TableCell align="right"><div >{JSON.parse(getSingleDeviceUserget?.DeviceSetting?.colors)?.map((i, k) => (
                                                <span key={k} style={{ marginRight: 10 }}>
                                                    {k + 1}:{i}
                                                </span>
                                            ))}</div></TableCell>
                                        </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.component || getSingleDeviceUserget?.DeviceSetting?.component === 0) && JSON.parse(getSingleDeviceUserget?.DeviceSetting?.component).length > 0 &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Կոմպոնենտ' : lang === 'ru' ? 'Компонент' : 'Component'}</div></TableCell>
                                            <TableCell align="right"><div >{JSON.parse(getSingleDeviceUserget?.DeviceSetting?.component)?.map((i, k) => (
                                                <span key={k} style={{ marginRight: 10 }}>
                                                    {k + 1}:{i}
                                                </span>
                                            ))}</div></TableCell>
                                        </TableRow>}
                                    {getSingleDeviceUserget?.DeviceSetting?.screen && JSON.parse(getSingleDeviceUserget?.DeviceSetting?.screen).length > 0 &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Էկրան' : lang === 'ru' ? 'Экран' : 'Screen'}</div></TableCell>
                                            <TableCell align="right"><div >{JSON.parse(getSingleDeviceUserget?.DeviceSetting?.screen)?.map((i, k) => (
                                                <span key={k} style={{ marginRight: 10 }}>
                                                    {k + 1}:{i}
                                                </span>
                                            ))}</div></TableCell>
                                        </TableRow>}
                                    {getSingleDeviceUserget?.Other?.screenColor && JSON.parse(getSingleDeviceUserget?.Other?.screenColor).length > 0 &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Էկրանի գույնը' : lang === 'ru' ? 'Цвет экрана' : 'Screen color'}</div></TableCell>
                                            <TableCell align="right"><div >{JSON.parse(getSingleDeviceUserget?.Other?.screenColor)?.map((i, k) => (
                                                <span key={k} style={{ marginRight: 10 }}>
                                                    {k + 1}:{i}
                                                </span>
                                            ))}</div></TableCell>
                                        </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.bonusMode || getSingleDeviceUserget?.DeviceSetting?.bonusMode === 0) &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >{lang === 'am' ? 'բոնուս մոդ' : lang === 'ru' ? 'бонусный мод' : 'bonus mode'}</div></TableCell>
                                            <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.bonusMode}</div></TableCell>
                                        </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.pauseMode || getSingleDeviceUserget?.DeviceSetting?.pauseMode === 0) &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Դադարի ռեժիմ' : lang === 'ru' ? 'Режим паузы' : 'Pause mode'}</div></TableCell>
                                            <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.pauseMode}</div></TableCell>
                                        </TableRow>}
                                    {(getSingleDeviceUserget?.DeviceSetting?.hpt || getSingleDeviceUserget?.DeviceSetting?.hpt === 0) &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >hpt</div></TableCell>
                                            <TableCell align="right"><div >{getSingleDeviceUserget?.DeviceSetting?.hpt}</div></TableCell>
                                        </TableRow>}
                                    {getSingleDeviceUserget?.Other?.flowSensor && JSON.parse(getSingleDeviceUserget?.Other?.flowSensor).length > 0 &&
                                        <TableRow>
                                            <TableCell component="th" scope="row"><div >{lang === 'am' ? 'Հոսքի սենսոր' : lang === 'ru' ? 'Датчик расхода' : 'Flow sensor'}</div></TableCell>
                                            <TableCell align="right"><div >{JSON.parse(getSingleDeviceUserget?.Other?.flowSensor)?.map((i, k) => (
                                                <div key={k}>
                                                    {k + 1})
                                                    <span style={{ marginRight: 10 }}>
                                                        {lang === 'am' ? 'պուլս' : lang === 'ru' ? 'пульс' : 'pulse'}-{i?.pulse}
                                                    </span>
                                                    <span style={{ marginRight: 10 }}>
                                                        {lang === 'am' ? 'ընդմիջում' : lang === 'ru' ? 'тайм-аут' : 'timeout'}-{i?.timeout}
                                                    </span>
                                                </div>
                                            ))}</div></TableCell>
                                        </TableRow>}



                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br /><br />
                        {/* <form onSubmit={serviceResetConfirmHandler}>
                            <button type="submit" className="reset_counts">{lang === 'am' ? 'զրոյացնել կարգավորումները' : lang === 'ru' ? 'онулировать натройки' : 'reset settings'}</button>
                        </form> */}

                        {getSingleDeviceUserget?.status && sf && <ChangeExtendedSettings verj={getSingleDeviceUserget} sf={sf} deviceUser={deviceUser} vaha={true} />}

                    </>
                }
            </div>

        </div >
    );
};

export default DeviceUser;
