// styles
import "./sidebar.scss";

import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import cookies from 'js-cookie'

// custom imports
import homeIcon from "../../images/homeImage.svg"
import analiticsIcon from "../../images/analitics.svg"
import AddEmail from "../modals/addEmail";
import { API_URI, token } from "../../utils/keys";
import robotIcon from "../../images/robotIcon.svg"
import changeIcon from "../../images/changeIcon.svg"
import logOutIcon from "../../images/logOutIcon.svg"
import LogoutModal from "../modals/logoutModal";
import logo from "../../images/blue_car_wash.png";

const Sidebar = () => {
  const [role, setRole] = useState([])
  const [user, setUser] = useState()
  const { t } = useTranslation()

  const lang = cookies.get('i18next')


  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  useEffect(() => {
    const instance = axios.create({
      baseURL: `${API_URI}/auth/me`,
      headers: { 'Authorization': `Bearer ${token}` }
    });

    instance.get(`${API_URI}/auth/me`)
      .then(response => {
        setRole(response.data.role)
        setUser(response.data)
      })
  }, [])


  // admin sidebar
  const adminDashboard = () => {
    return (
      <div className="center">
        <ul>
          <Link to="/list" style={{ textDecoration: "none" }}>
            <li>
              <img src={analiticsIcon} alt="image" />
              <span className={splitLocation[1] === "list" ? "active" : ""}>
                {lang == 'am' ? 'Օգտատերեր' : lang == 'ru' ? 'Пользователи' : 'Users'}
              </span>
            </li>
          </Link>
          <Link to="/components" style={{ textDecoration: "none" }}>
            <li>
              <img src={analiticsIcon} alt="image" />
              <span className={splitLocation[1] === "components" ? "active" : ""}>
                {lang == 'am' ? 'կոմպոնենտներ' : lang == 'ru' ? 'Компоненты' : 'Components'}
              </span>
            </li>
          </Link>
          <br /><br /><br /><br />

          <hr />
          <br />
          <Link to="/change" style={{ textDecoration: "none" }}>
            <li>
              <img src={changeIcon} />
              <span className={splitLocation[1] === "change" ? "active" : ""}>
                {lang == 'am' ? 'փոխել գաղտնաբառը' : lang == 'ru' ? 'изменить пароль' : 'Change Password'}
              </span>
            </li>
          </Link>

          <a to="#" style={{ textDecoration: "none" }}>

            <span><LogoutModal /></span>

          </a>
        </ul>
      </div>
    )
  }
  // technician sidebar

  const technicianDashboard = () => {
    return (
      <div className="center">
        <ul>
          <Link to="/userlist" style={{ textDecoration: "none" }}>
            <li>
              <img src={analiticsIcon} />
              <span className={splitLocation[1] === "userlist" ? "active" : ""}>
                {lang == 'am' ? 'Օգտատերեր' : lang == 'ru' ? 'Пользователи' : 'Users'}
              </span>
            </li>
          </Link>
          <Link to="/washingMachines" style={{ textDecoration: "none" }}>
            <li>
              <img src={robotIcon} />
              <span className={splitLocation[1] === "washingMachines" ? "active" : ""}>
                {lang == 'am' ? 'Ավտոլվացման կետ' : lang == 'ru' ? 'Мойка' : 'Machine list'}
              </span>
            </li>
          </Link>
          <br /><br /><br />

          <hr />
          <br />
          <Link to="/change" style={{ textDecoration: "none" }}>
            <li>
              <img src={changeIcon} />
              <span className={splitLocation[1] === "change" ? "active" : ""}>
                {lang == 'am' ? 'փոխել գաղտնաբառը' : lang == 'ru' ? 'изменить пароль' : 'Change Password'}
              </span>
            </li>
          </Link>



          <LogoutModal />

        </ul>
        <br />
        {user?.email == null && <AddEmail />}
      </div>
    )
  }

  // user sidebar

  const userDashboard = () => {
    return (
      <div className="center">
        <ul>
          <Link to="/" style={{ textDecoration: "none" }}>
            <li>
              <img src={homeIcon} />
              <span className={splitLocation[1] === "" ? "active" : ""}>
                {lang == 'am' ? 'վահանակ' : lang == 'ru' ? 'панель' : 'dashboard'}
              </span>
            </li>
          </Link>
          {/* <Link to="/washingMachinesUser" style={{ textDecoration: "none" }}>
            <li>
              <img src={robotIcon} />
              <span className={splitLocation[1] === "washingMachinesUser" ? "active" : ""}>
                {lang == 'am' ? 'Ավտոլվացման կետ' : lang == 'ru' ? 'Мойка' : 'Machine list'}
              </span>
            </li>
          </Link> */}
          <br /><br /><br /><br />

          <hr />
          <br />
          <Link to="/change" style={{ textDecoration: "none" }}>
            <li>
              <img src={changeIcon} />
              <span className={splitLocation[1] === "change" ? "active" : ""}>
                {lang == 'am' ? 'փոխել գաղտնաբառը' : lang == 'ru' ? 'изменить пароль' : 'Change Password'}
              </span>
            </li>
          </Link>




          <span><LogoutModal /></span>
        </ul>
        <br />
        {user?.email == null && <AddEmail />}
      </div>
    )
  }

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <div><img src={logo} style={{ width: "100px" }} alt="logo" /></div>
        </Link>
        <span className="user_name">{user && user?.username}</span>
      </div>

      {/*{role == undefined ? notAuth() : null}*/}
      {role == 'admin' ? adminDashboard() : null}
      {role == 'technician' ? technicianDashboard() : null}
      {role == 'user' ? userDashboard() : null}

    </div>
  );
};

export default Sidebar;