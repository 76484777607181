// styles
import "./modals.scss"


import * as React from 'react';
import { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";

// custom imports
import closeButton from '../../images/closeIcon.svg'
//import {goAddTechnician} from "../../redux/actions/addTechnicianAction";
import { goAddUser } from "../../redux/actions/addUserAction";
import { goOffFreeMode, goOnFreeMode, goResetCount } from "../../redux/actions/deviceAction";
import { useNavigate, useParams } from "react-router-dom";
import cookies from 'js-cookie';
import { useEffect } from 'react';
import Swal from "sweetalert2";



export default function ResetCounts({ idd, success }) {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const lang = cookies.get('i18next')

    const handleOpen = async () => {
        if (success) {
            setOpen(true);
        } else {
            await Swal.fire({
                position: 'top-end',
                icon: "error",
                title: "Oops...",
                text: lang === 'am' ? 'Ընտրեք սարքերը' : lang === 'ru' ? 'Выберите устройства' : 'Select the devices',
                timer: 1500,
            });
        }
    };
    const handleClose = () => {
        setOpen(false);
    };

    const onResetCounts = e => {
        e.preventDefault()

        let cmdata = {}
        cmdata.devices = { id: [...idd] }
        cmdata.token = localStorage.getItem("bestToken")
        setOpen(false)
        dispatch(goResetCount(cmdata))
    }


    return (
        <div >
            <Button className="link" onClick={handleOpen}>{lang === 'am' ? 'Զրոյացնել հաշվիչները' : lang === 'ru' ? 'Сбросить счетчики' : 'Reset Counts'}</Button>
            {open && <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description">

                <div className="container_modal">
                    <form onSubmit={onResetCounts} id="contact">
                        <h3>{lang === 'am' ? 'Զրոյացնել հաշվիչները' : lang === 'ru' ? 'Сбросить счетчики' : 'Reset Counts'}</h3>
                        <fieldset>
                            <button type="submit" id="contact-submit">
                                {lang === 'am' ? 'զրոյացնել' : lang === 'ru' ? 'сбросить' : 'reset'}
                            </button>

                        </fieldset>
                        <div onClick={handleClose} className="closeBtn" style={{ display: 'block' }}>
                            <img src={closeButton} onClick={handleClose} alt="close icon" />
                        </div>
                    </form>
                </div>
            </Modal>}
        </div>
    );
}