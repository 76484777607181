// styles
import "./single.scss";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";


// custom imports
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import user_img from "../../images/user.png"
import { API_URI, token } from "../../utils/keys";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cookies from 'js-cookie';
import { getUsers } from "../../redux/actions/getUsersAction";

const Single = () => {
  const { user } = useParams()
  const { t } = useTranslation()
  const [devices, setDevices] = useState();

  const dispatch = useDispatch()
  const lang = cookies.get('i18next')

  const users = useSelector(state => state.getUsers.users.filter(i => i.id == +user)[0])

  useEffect(() => {
    dispatch(getUsers())
  }, [])



  useEffect(() => {
    const instance = axios.create({
      baseURL: `${API_URI}/car-wash`,
      headers: { 'Authorization': `Bearer ${token}` }
    });

    instance.get(`${API_URI}/car-wash`)
      .then(response => {
        setDevices(response.data?.filter((one) => Number(one.user_id) === Number(user)))
      })
      .catch(e => {
        console.error(e, 'error')
      })
  }, [0])

  console.log(devices)

  return (
    <div className="single">
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <h1 className="title">{lang === 'am' ? 'Ինֆորմացիա' : lang === 'ru' ? 'Информация' : 'Information'}</h1>
            <div className="item">
              <img
                src={user_img}
                alt="img"
                className="itemImg"
              />
              <div className="details">
                <h1 className="itemTitle">{lang === 'am' ? 'Անուն' : lang === 'ru' ? 'Имя' : 'Username'} - {users?.username}</h1>
                <div className="detailItem">
                  <span className="itemKey">{lang === 'am' ? 'Էլ․ հասցե' : lang === 'ru' ? 'Эл. адрес' : 'Email'}:</span>
                  <span className="itemValue">{users?.email === null ? (lang === 'am' ? 'Էլ․ հասցեն դեռ ավելացված չէ' : lang === 'ru' ? 'Эл. адрес еще не добавлено' : 'Email not added yet') : users?.email}</span>
                </div>
                {/*<div className="detailItem">*/}
                {/*  <span className="itemKey">Phone:</span>*/}
                {/*  <span className="itemValue">+7777777777777</span>*/}
                {/*</div>*/}
                <div className="detailItem">
                  <span className="itemKey">{lang === 'am' ? 'Քանակ' : lang === 'ru' ? 'Число мойок' : 'Carwashs count'}:</span>
                  <span className="itemValue">
                    {devices?.length || 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Single;