// styles
import "./style/App.scss"

import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useLayoutEffect, useState } from "react";

// custom imports

// import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
// import New from "./pages/new/New";
import ChangePassword from "./pages/changePassword/ChangePassword";
import Layout from "./components/Layout";
import SendCode from "./pages/sendCode/SendCode";
import EnterCode from "./pages/enterCode/EnterCode";
import FinishChange from "./pages/finishChange/FinishChange";
import DashboardAdmin from "./pages/DashboardAdmin/DashboardAdmin";
import UserDashboard from "./pages/UserDashboard/UserDashboard";
import TechnicianDashboard from "./pages/TechnicianDashboard/TechnicianDashboard";
import PageNotFound from "./pages/pageNotFound/PageNotFound";
import axios from "axios";
import { API_URI, token } from "./utils/keys";
import WashingMachines from "./pages/washingMachines/WashingMachines";
import AddMachines from "./pages/addMachines/AddMachines";
import ViewMachine from "./pages/viewMachine/ViewMachine";
import DevicePage from "./pages/devicePage/DevicePage";
import SingleDevice from "./pages/devicePage/SingleDevice";
import LoadingPage from "./pages/LoadingPage/LoadingPage";
import TokenCode from "./pages/tokenCode/TokenCode";
import WashingMachinesUser from "./pages/washingMachines/washingMachinesUser/WashingMachinesUser";
import ViewMachineUser from "./pages/viewMachine/ViewMachineUser";
import DeviceUser from "./pages/deviceUser/DeviceUser";
import ComponentsAdmin from "./components/componentsAdmin/ComponentsAdmin";
import BasicEdit from "./pages/LoadingPage/BasicEdit";
import NiceLoadingPage from "./pages/LoadingPage/NiceLoadingPage";
function App() {
    const [loading, setLoading] = useState(true);
    const [role, setRole] = useState([])
    const navigate = useNavigate();
    const getAuth = useSelector((state) => state.authReducer?.getAuth?.role);

    useEffect(() => {
        switch (role) {
            case 'technician':
                localStorage.setItem("roleId", "2")
                break;
            case 'admin':
                localStorage.setItem("roleId", "1")
                break;
            case 'user':
                localStorage.setItem("roleId", "3")
                break;
            default:
                localStorage.setItem("roleId", null)
        }
    }, [role])

    // useEffect(() => {
    //    setTimeout(() => setLoading(false), 2000)
    // }, [])
    useLayoutEffect(() => {
        const funcProb = async () => {
            const instance = await axios.create({
                baseURL: `${API_URI}/auth/me`,

                headers: { 'Authorization': `Bearer ${token}` }
            });

            await instance.get(`${API_URI}/auth/me`)
                .then(response => {

                    setRole(response.data.role)
                    setLoading(false)

                })
                .catch(e => {

                    navigate('/login')
                    setLoading(false)
                })
        }
        funcProb()
    }, [])



    const adminRole = () => {
        return (
            <Route path="/" element={<Layout />}>
                <Route path="/" element={<Navigate to="/list" />} />
                <Route path="/components" element={<ComponentsAdmin />} />
                <Route index path="/list" element={<List />} />
                {/*change password start*/}
                <Route path="/change" element={<ChangePassword />} />
                <Route path="/change/changeCode" element={<SendCode />} />
                <Route path="/change/changeCode/enterCode" element={<EnterCode />} />
                <Route path="/change/changeCode/enterCode/finishChange" element={<FinishChange />} />
                {/*change password end*/}
                <Route path="/users/new" element={<Single />} />
                <Route path="/dashboardAdmin" element={<DashboardAdmin />} />
                <Route path="/dashboardUser" element={<UserDashboard />} />
                {/*loading*/}
                <Route path="/loadingPage" element={<LoadingPage />} />
            </Route>
        )
    }


    const technicianRole = () => {
        return (
            <Route path="/" element={<Layout />}>
                <Route path="/" element={<Navigate to="/userlist" />} />
                <Route path="/" element={<TechnicianDashboard />} />
                {/*<Route path="/list" element={<List />} />*/}
                <Route path="/userlist" element={<List />} />
                <Route path="/devices" element={<DevicePage />} />
                <Route path="/devices/:deviceUser" element={<SingleDevice />} />
                {/*change password start*/}
                <Route path="/change" element={<ChangePassword />} />
                <Route path="/change/changeCode" element={<SendCode />} />
                <Route path="/change/changeCode/enterCode" element={<EnterCode />} />
                <Route path="/change/changeCode/enterCode/finishChange" element={<FinishChange />} />
                {/*change password end*/}
                <Route path="/users/:user" element={<Single />} />
                {/*washing machines start*/}

                <Route path="/washingMachines" element={<WashingMachines />} />
                <Route path="/addMachines" element={<AddMachines />} />
                <Route path="/viewMachine/:userId" element={<ViewMachine />} />
                <Route path="/singleDevice/:deviceUser" element={<SingleDevice />} />
                {/*washing machines end*/}

                {/*loading*/}
                <Route path="/loadingPage" element={<LoadingPage />} />
                {/*token*/}
                <Route path="/tokenCode" element={<TokenCode />} />
            </Route>
        )
    }


    const userRole = () => {
        return (
            <Route path="/" element={<Layout />}>
                <Route path="/" element={<UserDashboard />} />
                <Route path="/list" element={<List />} />
                <Route path="/washingMachinesUser" element={<WashingMachinesUser />} />
                {/*change password start*/}
                <Route path="/change" element={<ChangePassword />} />
                <Route path="/change/changeCode" element={<SendCode />} />
                <Route path="/change/changeCode/enterCode" element={<EnterCode />} />
                <Route path="/change/changeCode/enterCode/finishChange" element={<FinishChange />} />
                {/*loading*/}
                <Route path="/loadingPage" element={<LoadingPage />} />
                {/*change password end*/}
                <Route path="/users/:user" element={<Single />} />
                <Route path="/viewMachineUser/:userId" element={<ViewMachineUser />} />
                <Route path="/deviceUserSingle/:deviceUser" element={<DeviceUser />} />
                <Route path="/basicEdit" element={<BasicEdit />} />

            </Route>
        )
    }


    const notAuth = () => {
        return (
            <>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Login />} />
                <Route path="/change/changeCode" element={<SendCode />} />
                <Route path="/change/changeCode/enterCode" element={<EnterCode />} />
                <Route path="/change/changeCode/enterCode/finishChange" element={<FinishChange />} />
                {/*loading*/}
                <Route path="/loadingPage" element={<LoadingPage />} />
                <Route path="/*" element={<Login />} />
            </>
        )
    }


    return (
        <div>

            {loading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', fontSize: '2rem', color: 'white' }}>Loading... </div>}

            <Routes>
                {!token && notAuth()}
                {(role == undefined || role == null) || getAuth ? notAuth() : null}
                {role == 'admin' ? adminRole() : null}
                {role == 'technician' ? technicianRole() : null}
                {role == 'user' ? userRole() : null}



                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
            </Routes>




        </div>
    );
}

export default App;
