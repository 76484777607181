//styles
import "./UserCounterTable.scss";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { getDevicesCounters } from "../../redux/actions/deviceAction";
import cookies from "js-cookie";
import axios from "axios";
import Swal from "sweetalert2";
import ChangeSettingsBasic from "../modals/changeSettingsBasic";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";
import { Button } from "@mui/material";
import moment from "moment";
import Loader from '../../images/loader.png'

const AllDevicesUser = ({ idd, setIdd }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userId } = useParams();
  let [totalBillPoint, setTotalBillPoint] = useState([])
  let [totalCoin, setTotalCoin] = useState([])
  let [cashless, setCashless] = useState([])
  const [start, setStart] = useState([])
  const [end, setEnd] = useState([])
  const [value1, setValue1] = useState([])
  const [qwe, setQwe] = useState(0)
  const [value, setValue] = useState([])
  const [tver, setTver] = useState([])
  const [render, setRender] = useState(0)
  const [loader, setLoader] = useState([])
  let [vid, setVid] = useState([])
  const [verj, setVerj] = useState(0)
  const lang = cookies.get("i18next");
  const [checked, setChecked] = useState(false);
  const currentDate = new Date();
  const [secondRender, setSecondRender] = useState(0)
  const getCounters2 = useSelector(
    (state) => state.deviceReducer.getDevicesCounters
  );
  const [getCounters, setGetCounters] = useState([]);
  useEffect(() => {
    dispatch(getDevicesCounters(userId));
  }, []);



  useEffect(() => {
    const asdd = getCounters2.reverse();
    
    setGetCounters(asdd);
    if(secondRender === 0) {
      setSecondRender(1)
      if(!asdd.some(i => i.status === true)) {
        setTimeout(() => { 
        dispatch(getDevicesCounters(userId))
       
      }, 3000)

        
      }
	setSecondRender(2)
    }
  }, [getCounters2]);
  const qweqwe = localStorage.getItem("bestToken");

  useEffect(() => {
    setVid([])
    setTotalBillPoint([])
    setTotalCoin([])
    setCashless([])
    if (getCounters2.length > 0) {
      let wantedStart = []
      let wantedEnd = []
      let wantedValue1 = []
      let k = []
      getCounters2.forEach(() => {
        wantedValue1.push('coin')
        wantedStart.push(moment(new Date()).subtract(1, 'month').format('YYYY-MM-DD'))
        wantedEnd.push(moment(new Date()).format('YYYY-MM-DD'))
      })
      setValue1(wantedValue1)
      setStart(wantedStart)
      setEnd(wantedEnd)
      setVerj(prev => prev + 1)
    }


  }, [getCounters2])
  //   const handleClick = async (wantedIndex) => {
  //     // axios.get(`${API_URI}/nats-cnf?` + new URLSearchParams({
  //     //     start, end, pId: value
  //     // }))
  //     loader[wantedIndex] = true
  //     setLoader([...loader])
  //     await axios.get(`https://api.zxprof.com/api/nats-cnf?start=${new Date(start[wantedIndex])}&end=${new Date(end[wantedIndex])}&pId=${value[wantedIndex]}`)
  //         .then((response) => response.data)
  //         .then((data) => data.dms)
  //         .then((val) => {
  //             vid[wantedIndex] = []
  //             setVid([...vid])
  //             if (val[0]?.dataMsg) {

  //                 let wantedData = []
  //                 let wantedAsd = []
  //                 let truthTver = []

  //                 val.forEach((element, index) => {
  //                     if (element) {
  //                         if (element.dataMsg) {
  //                             const asd = JSON.parse(element.dataMsg)

  //                             if (tver[wantedIndex].length < asd.length) {
  //                                 truthTver = []
  //                                 asd.forEach((i, k) => {

  //                                     truthTver?.push(k + 1)
  //                                 })
  //                             }
  //                             wantedData = asd
  //                             wantedAsd.push({ data: asd, createdAt: element.createdAt })

  //                         }
  //                     } else {
  //                         wantedAsd.push(null)
  //                     }
  //                 });
  //                 tver[wantedIndex] = [...truthTver]
  //                 setTver([...tver])
  //                 let wantedTotal = total
  //                 wantedTotal[wantedIndex] = wantedData
  //                 let wantedVid = vid
  //                 wantedVid[wantedIndex] = wantedAsd


  //                 // setTotal([...total])
  //                 // setTotalBillPoint(asd[qwe].bill.t + asd[qwe].bill.d);
  //                 // setTotalCoin(asd[qwe].coin.t + asd[qwe].coin.d);
  //                 // setCashless(asd[qwe].cashless.t + asd[qwe].cashless.d)

  //                 loader[wantedIndex] = false
  //                 setLoader([...loader])
  //                 setTotal(wantedTotal)
  //                 setVid(wantedVid)
  //             }
  //         })
  //     setRender(prev => prev + 1)
  // }
  const handleClick = async (wantedIndex) => {
    // axios.get(`${API_URI}/nats-cnf?` + new URLSearchParams({
    //     start, end, pId: value
    // }))
    loader[wantedIndex] = true
    setLoader([...loader])
    await axios.get(`https://api.zxprof.com/api/nats-cnf?start=${new Date(start[wantedIndex])}&end=${new Date(end[wantedIndex])}&pId=${qweqwe}`)
        .then((response) => response.data)
        .then((data) => data.dms)
        .then((val) => {
            vid[wantedIndex] = []
            setVid([...vid])
            // if (val[0]?.dataMsg) {

                let wantedData = []
                let wantedAsd = []


                val.forEach((element, index) => {
                    if (element) {
                        if (element.dataMsg) {
                            const asd = JSON.parse(element.dataMsg)


                            wantedData = asd
                            wantedAsd.push({ data: asd, createdAt: element.createdAt })

                        }
                    } else {
                        wantedAsd.push(null)
                    }
                });
                // let wantedTotal = total
                // wantedTotal[wantedIndex] = wantedData
                let wantedVid = vid
                wantedVid[wantedIndex] = wantedAsd


                // setTotal([...total])
                // setTotalBillPoint(asd[qwe].bill.t + asd[qwe].bill.d);
                // setTotalCoin(asd[qwe].coin.t + asd[qwe].coin.d);
                // setCashless(asd[qwe].cashless.t + asd[qwe].cashless.d)

                loader[wantedIndex] = false
                setLoader([...loader])
                // setTotal(wantedTotal)
                setVid(wantedVid)
            // }
        })
    setRender(prev => prev + 1)
}

  const [furion, setFurion] = useState([]);
  useEffect(() => {
    axios
      .post("https://api.zxprof.com/cmd/nats-sbr", { cmdata: qweqwe })
      .then((res) => {
        if (res.data.data == 1) {
          axios
            .get(`https://api.zxprof.com/api/nats-sbr?pId=${qweqwe}`)
            .then((res) => {
              const asdasdasd = JSON.parse(res.data.dms.dataMsg);
              setFurion(asdasdasd);
            });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            timer: 1500,
          });
        }
      });
  }, []);

  const handleUseEffect = async () => {
    // axios.get(`${API_URI}/nats-cnf?` + new URLSearchParams({
    //     start, end, pId: value
    // }))
    await axios.get(`https://api.zxprof.com/api/nats-cnf?start=${new Date(start[0])}&end=${new Date(end[0])}&pId=${qweqwe}`)
    .then((response) => response.data)
    .then((data) => data.dms)
    .then((val) => {
      
                setVid([])
                // if (val[0]?.dataMsg) {

                    let wantedData = []
                    let wantedAsd = []

                    val.forEach((element, index) => {
                        if (element) {
                            if (element.dataMsg) {
                                const asd = JSON.parse(element.dataMsg)

                                wantedData = asd
                                wantedAsd.push({ data: asd, createdAt: element.createdAt })

                            }
                        } else {
                            wantedAsd.push(null)
                        }
                    });
                    
                    let wantedVid = []
                    getCounters2.forEach(() => {
                      wantedVid.push(wantedAsd)
                    })


                    // setTotal([...total])
                    // setTotalBillPoint(asd[qwe].bill.t + asd[qwe].bill.d);
                    // setTotalCoin(asd[qwe].coin.t + asd[qwe].coin.d);
                    // setCashless(asd[qwe].cashless.t + asd[qwe].cashless.d)

                    // loader[wantedIndex] = false
                    // setLoader([...loader])
                    setVid(wantedVid)


//  }
})
    setRender(prev => prev + 1)
    const wantedLoaderArr = []
    loader.forEach(() => wantedLoaderArr.push(false))
    setLoader(wantedLoaderArr)
}
useEffect(() => {
  if (verj   > 0) {
      handleUseEffect()
  }
}, [verj])

useEffect(() => {
  if (verj.length > 0) {
      handleUseEffect()
  }
}, [verj])

  const handleChange = () => {
    if (checked) {
      setChecked(false);
      setIdd([]);
    } else {
      setChecked(true);
      setIdd([]);
      for (let i = 0; i < getCounters.length; i++) {
        if (getCounters?.[i].status === true) {
          setIdd((prev) => [...prev, i + 1]);
        }
      }
    }
  };

  return (
    <div className="user_all_devices">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span>
          {lang === "am" ? "սարքեր" : lang === "ru" ? "устройства" : "devices"}
        </span>
        <label
          htmlFor="chec"
          style={{
            color: "white",
            fontSize: 15,
            userSelect: "none",
            cursor: "pointer",
          }}
        >
          <input
            id={"chec"}
            type={"checkbox"}
            onChange={handleChange}
            checked={idd.length === getCounters.length}
            style={{ position: "relative", top: 15 }}
          />{" "}
          {lang === "am"
            ? "Ընտրել բոլոր սարքերը"
            : lang === "ru"
              ? "Выбрать все устройства"
              : "Select all devices"}
        </label>
      </div>
      <br /> <br />
      {getCounters?.map((i, k) => {
        const wantedVid = []
        let tempData = {}
        const START = moment.utc(start[k]).startOf('day');
        const NOW = moment.utc(end[k]).endOf('day');
     
        let current = moment.utc(START);
    
        const isLessThanTwoDays = NOW.diff(START, 'days') < 2;
        const isMoreThanMonth = current.clone().add(1, 'month').add(1, 'day').isSameOrBefore(NOW);
    
        vid[k]?.forEach((i, wantedK) => {
            let wantedCurrent = ''
            if (isLessThanTwoDays) {
                wantedCurrent = current.clone().add(wantedK, 'hours').format('MM/DD/YYYY HH:mm');
            } else if (isMoreThanMonth) {
                wantedCurrent = current.clone().add(wantedK, 'months').format('MM/YYYY')
            } else {
                  wantedCurrent = current.clone().add(wantedK, 'days').format('MM/DD/YYYY');
            }
            if (wantedK !== 0) {
                const wantedCoin = +i?.data[k]?.coin.t && +tempData.coinT && +tempData.coinT !== 0 ? +i?.data[k]?.coin.t - +tempData.coinT : 0; 
                const wantedBill = +i?.data[k]?.bill.t && +tempData.billT && +tempData.billT !== 0 ? +i?.data[k]?.bill.t - +tempData.billT : 0; 
                const wantedCashless = +i?.data[k]?.cashless.t && +tempData.cashlessT && +tempData.cashlessT !== 0 ? +i?.data[k]?.cashless.t - +tempData.cashlessT : 0;
                const test2 = {
                    name: wantedCurrent,
                    coinT: (!wantedCoin || wantedCoin < 0) ? 0 : wantedCoin  ,
                    // coinD: i.data[qwe[k]]?.coin.d,
                    billT: (!wantedBill || wantedBill < 0) < 0 ? 0 : wantedBill,
                    // billD: i.data[qwe[k]]?.bill.d,
                    cashlessT: (!wantedCashless || wantedCashless < 0) < 0 ? 0 : wantedCashless,
                    // cashlessD: i.data[qwe[k]]?.cashless.d,

                }
                tempData = {

                    coinT: +i?.data[k]?.coin.t || +tempData.coinT || 0,
                    // coinD: i.data[qwe[k]]?.coin.d,
                    billT: +i?.data[k]?.bill.t || +tempData.billT || 0,
                    // billD: i.data[qwe[k]]?.bill.d,
                    cashlessT: +i?.data[k]?.cashless.t || +tempData.cashlessT || 0,
                    // cashlessD: i.data[qwe[k]]?.cashless.d,

                }
                wantedVid.push(test2)
            } else {
                tempData = {
                    coinT: +i?.data[k]?.coin.t || +tempData.coinT || 0,

                    billT: +i?.data[k]?.bill.t || +tempData.billT || 0,
                    cashlessT: +i?.data[k]?.cashless.t || +tempData.cashlessT || 0,

                }
            }
        })

       return (
          <div key={k}>
            <Card
              className="get_counters_slice"
              sx={{ minWidth: 275, backgroundColor: idd?.includes(k + 1) ? 'yellow' : i?.disabled ? 'red' : 'transparent', }}
              onClick={() => {
                if (i.status) {
                  if (idd?.includes(k + 1)) {
                    let asdqwe = [...idd];
                    const qweasd = idd.indexOf(k + 1);
                    asdqwe.splice(qweasd, 1);
                    setIdd(asdqwe);
                  } else {
                    setIdd((prev) => [...prev, k + 1]);
                  }
                }
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', }}>
                <div style={{ display: 'flex', }}>
                  <CardContent
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      width: "100%",
                    }}
                  >

                    <div >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.disabled"
                        gutterBottom
                      >
                        {lang === "am"
                          ? "Սարքի Անուն"
                          : lang === "ru"
                            ? "Имя Устройства"
                            : "Device Name"}{" "}
                        - {i?.name}
                      </Typography>
                      {secondRender === 1 ? <div style={{ width: "100%", height: 50, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={Loader} className='spinnerForever' style={{ width: 15, height: 15 }} alt='' />
                                                </div> :
                      <div onClick={(e) => e.stopPropagation()}>
                        <div
                          style={{
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            gap: 6,
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: i?.status ? 'green' : 'red',
                              width: 15,
                              height: 15,
                              border: '1px solid black',
                              borderRadius: "50%",
                              display: "inline-block",
                            }}
                          ></div>{" "}
                          <span style={{ color: "white", fontSize: 14 }}>
                            {i?.status ? 'Online' : 'Offline'}
                          </span>
                        </div>
                        <Typography
                          sx={{ fontSize: 12, color: "white" }}
                          color="text.disabled"
                          gutterBottom
                        >
                          {lang === "am"
                            ? "Անվճար ռեժիմ"
                            : lang === "ru"
                              ? "Бесплатный режим"
                              : "Free mode"}{" "}
                          -{" "}
                          {i?.Other?.freeMode
                            ? lang === "am"
                              ? "Միացված է"
                              : lang === "ru"
                                ? "Включён"
                                : "enabled"
                            : lang === "am"
                              ? "Անջատված է"
                              : lang === "ru"
                                ? "Выключен"
                                : "disabled"}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 12, color: "white" }}
                          color="text.disabled"
                          gutterBottom
                        >
                          {lang === "am"
                            ? "Գումարի ընդհանուր չափ"
                            : lang === "ru"
                              ? "Общая сумма денег"
                              : "Total amount of money"}{" "}
                          - {i?.Counter.billT + i?.Counter.coinT + i?.Counter.cashlessT} {lang === 'am' ? 'դրամ' : lang === 'ru' ? 'монет' : 'coin'}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 12, color: "white" }}
                          color="text.disabled"
                          gutterBottom
                        >
                          {lang === "am"
                            ? "Ընթացիկ դրամարկղ"
                            : lang === "ru"
                              ? "Текущая касса"
                              : "Current cash register"}{" "}
                          - {i?.Counter.billD + i?.Counter.coinD + i?.Counter.cashlessD} {lang === 'am' ? 'դրամ' : lang === 'ru' ? 'монет' : 'coin'}
                        </Typography>

                        {i?.status && furion.length >= 1 &&
                          furion.filter((z) => z.id === k + 1).length >=
                          1 &&
                          furion
                            .filter((z) => z.id === k + 1)
                            .map((x, y) => (
                              <Fragment key={y}>
                                <ChangeSettingsBasic singleDevice={x} />
                              </Fragment>
                            ))}
                      </div>}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                        <div>
                          <h3 className="income_graph_text">
                            {lang == 'am' ? 'եկամուտների գրաֆիկ' : lang == 'ru' ? 'график доходов' : 'Income Graph'}
                          </h3>
                          {loader[k] ? <div style={{ width: 600, height: 230, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={Loader} className='spinnerForever' style={{ width: 100, height: 100 }} alt='' />
                                                </div> :
                          <BarChart
                            width={600}
                            height={230}
                            data={wantedVid}
                            margin={{
                              top: 20,
                              right: 80,
                              bottom: 20,
                              left: 20
                            }}
                            style={{ margin: "0 auto" }}>


                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {value1[k] === 'coin' && <Bar type="monotone" dataKey="coinT" fill="#82ca9d" />}
                            {value1[k] === 'bill' && <Bar type="monotone" dataKey="billT" fill="#82ca9d" />}
                            {value1[k] === 'cashless' && <Bar type="monotone" dataKey="cashlessT" fill="#82ca9d" />}
                          </BarChart>}

                        </div>
                      </div>
                      {/* <div className="wrapper_block">
                        <span className="car_wash_bill_point" style={{ color: 'white', fontSize: 11 }}>
                          {lang == 'am' ? 'Թղթադրամներով գումար' : lang == 'ru' ? 'Деньги в банкнотах' : 'Money in banknotes'} - {totalBillPoint?.[k]}  {lang === 'am' ? 'դրամ' : lang === 'ru' ? 'монет' : 'coin'}
                        </span>
                        <span className="car_wash_bill_point" style={{ color: 'white', fontSize: 11 }}>
                          {lang == 'am' ? 'Մետաղադրամներով գումար' : lang == 'ru' ? 'деньги в монетах' : 'money in coins'} - {totalCoin?.[k]}  {lang === 'am' ? 'դրամ' : lang === 'ru' ? 'монет' : 'coin'}
                        </span>
                        <span className="car_wash_bill_point" style={{ color: 'white', fontSize: 11 }}>
                          {lang == 'am' ? 'Անկանխիկ վճարումների գումար' : lang == 'ru' ? 'Безналичный расчет' : 'Cashless Payments'} - {cashless?.[k]}  {lang === 'am' ? 'դրամ' : lang === 'ru' ? 'монет' : 'coin'}
                        </span>
                        <span className="car_wash_bill_point" style={{ color: 'white', fontSize: 11 }}>
                          {lang == 'am' ? 'ընդհանուր գումար' : lang == 'ru' ? 'Итого' : 'total amount'} - {totalCoin?.[k] + cashless?.[k] + totalBillPoint?.[k]}  {lang === 'am' ? 'դրամ' : lang === 'ru' ? 'монет' : 'coin'}
                        </span>
                      </div> */}
                    </div>


                  </CardContent>
                  <div
                    style={{ maxHeight: 85 }}
                    onClick={() => {
                      localStorage.setItem(
                        "oneDevice",
                        furion?.filter((z) => z.id === k + 1)?.[0]?.id
                      )
                      window.location.replace(`/deviceUserSingle/${i.id}`)
                    }
                    }
                  >
                    <button className="view_device" style={{ maxHeight: 50 }}>
                      {lang === "am"
                        ? "Տեսնել"
                        : lang === "ru"
                          ? "Посмотреть"
                          : "View"}
                    </button>
                  </div>
                </div>
                <div className='veryNew' onClick={(e) => e.stopPropagation()} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', alignSelf: 'center' }} >

                  <div >
                    <label htmlFor="start" style={{ color: 'white' }}>{lang == 'am' ? 'Տիպ' : lang == 'ru' ? 'Тип' : 'Type'}:</label>
                    <select defaultValue={'coin'} onChange={(e) => {
                                                    value1[k] = e.target.value
                                                    setValue1([...value1])
                                                }}>
                      <option value={'coin'}>  {lang == 'am' ? 'Մետաղադրամ' : lang == 'ru' ? 'Монета' : 'Coin'}</option>
                      <option value={'bill'}> {lang == 'am' ? 'Բիլլ' : lang == 'ru' ? 'Счет' : 'Bill'}</option>
                      <option value={'cashless'}> {lang == 'am' ? 'Անկանխիկ' : lang == 'ru' ? 'Безналичный' : 'Cashless'}</option>
                    </select>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-start' }}>
                    <label htmlFor="start" style={{ color: 'white', fontSize: 11 }}>{lang == 'am' ? 'Մեկնարկի ամսաթիվ' : lang == 'ru' ? 'Дата начала' : 'Start date'}:</label>

                    <input type="date" max={new Date(end?.[k])?.toISOString().split('T')[0]} style={{ padding: 0 }}
                      value={start[k]} onChange={(e) => {
                        start[k] = e.target.value
                        setStart([...start])
                      }}
                    />
                  </div>
                  <fieldset style={{ display: 'flex', flexDirection: 'column' }}>
                    <label htmlFor="start" style={{ color: 'white', fontSize: 11 }}>{lang == 'am' ? 'Ավարտի ամսաթիվ' : lang == 'ru' ? 'Дата окончания' : 'End date'}:</label>

                    <input type="date" id="start" name="trip-start" min={new Date(start?.[k])?.toISOString().split('T')[0]} max={currentDate.toISOString().split('T')[0]} style={{ padding: 0 }}
                      value={end[k]} onChange={(e) => {
                        end[k] = e.target.value
                        setEnd([...end])
                      }}
                    />
                  </fieldset>
                  <div className="asdasd">
                    <Button disabled={loader[k]} onClick={() => handleClick(k)} >{lang == 'am' ? 'ՄՈՒՏՔԱԳՐԵԼ' : lang == 'ru' ? 'ВСТАВИТЬ' : 'SUBMIT'}</Button>
                  </div>
                </div>
              </div>
            </Card>
            <br />
          </div>
        )
      })}
    </div>
  )
};

export default AllDevicesUser;
