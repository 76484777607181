//styles
import "./TokenCode.scss"

import React, { useRef, useState } from 'react';
import Navbar from "../../components/navbar/Navbar";
import { useSelector } from "react-redux";
import { addMachineReducer } from "../../redux/reducers/addMachineReducer";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import cookies from 'js-cookie';


const TokenCode = () => {

    const { t } = useTranslation()
    const getToken = useSelector(state => state.addMachineReducer.token)

    const [copySuccess, setCopySuccess] = useState('');
    const textAreaRef = useRef(null);

    const lang = cookies.get('i18next')

    function copyToClipboard(e) {
        textAreaRef.current.select();
        document.execCommand('copy');
        setCopySuccess("Success")
    };



    return (
        <div className="token_code_section">
            <Navbar />
            <div className="token_code_vh">
                <div style={{ color: "green", fontSize: "30px" }}>{copySuccess}</div>
                <span>{lang === 'am' ? 'Ներքևում սարքի տոկենն է,խնդրում ենք պահել ինչ որ տեղ,այլևս չի ցուցադրվելու' : lang === 'ru' ? 'Токен устройства находится внутри, пожалуйста, сохраните его где-нибудь, он больше не будет отображаться' : 'The token of the device is inside, please keep it somewhere, it will not be displayed anymore'}</span>

                <div style={{ position: "relative" }}>
                    {
                        document.queryCommandSupported('copy') &&
                        <div >
                            <button onClick={copyToClipboard}><i className="fa-solid fa-copy"></i></button>
                        </div>
                    }
                    <form>
                        <textarea
                            ref={textAreaRef}
                            value={getToken?.carWashPoint?.token == null ? (lang === 'am' ? 'token-ը արդեն ցուցադրվել է' : lang === 'ru' ? 'токен уже отображался' : 'token has already displayed') : getToken?.carWashPoint?.token}
                        />
                    </form>
                </div>


                <Link to="/washingMachines"><button className="button_token">{lang === 'am' ? 'վերադառնալ' : lang === 'ru' ? 'Вернутся' : 'Come back'}</button></Link>
            </div>

        </div>
    );
};

export default TokenCode;

