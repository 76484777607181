import React, { memo, useEffect, useState } from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { API_URI, token } from "../../utils/keys";
import axios from "axios";
import cookies from 'js-cookie';

const DeviceUserGetCounters = ({ getSingleDeviceUserget, oneFlag, twoFlag }) => {
    const { t } = useTranslation()

    const lang = cookies.get('i18next')


    //     counterOnline === 200 ?
    //         <div className="online_offline_slice">
    //             <div >Online</div>
    //             <span className="small_ball"></span>
    //         </div>
    //         : <div className="online_offline_slice">
    //             <div >Offline</div>
    //             <span className="small_ball small_ball_red"></span>
    //         </div>
    // } 

    return (
        <>
            <Card sx={{ minWidth: 345 }}>
                <CardContent >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Typography gutterBottom variant="h5" component="div" >
                                <div > {lang === 'am' ? 'Սարքի անուն' : lang === 'ru' ? 'Имя устройства' : 'Device name'} - {getSingleDeviceUserget?.name}</div>
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div">
                                <div > {lang === 'am' ? 'Ստեղծվել է' : lang === 'ru' ? 'Создано на' : 'createdAt'} - {getSingleDeviceUserget?.createdAt?.substring(0, 10)}</div>
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div" style={{ display: 'flex', alignItems: 'center' }}>
                                {getSingleDeviceUserget?.status ? <><span style={{ backgroundColor: 'green', borderRadius: '50%', marginRight: '5px', width: '25px', height: '25px', display: 'inline-block' }}></span>Online</> : <><span style={{ backgroundColor: 'red', borderRadius: '50%', marginRight: '5px', width: '25px', height: '25px', display: 'inline-block' }}></span>Offline</>}
                            </Typography>
                        </div>
                        <div>
                            <Typography gutterBottom variant="h5" component="div">
                                {lang === 'am' ? 'Անվճար ռեժիմ' : lang === 'ru' ? 'Бесплатный режим' : 'Free mode'} - {oneFlag ? (lang === 'am' ? 'Միացված է' : lang === 'ru' ? 'Включён' : 'enabled') : (lang === 'am' ? 'Անջատված է' : lang === 'ru' ? 'Выключен' : 'disabled')}
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div">
                                {lang === 'am' ? 'Սարք' : lang === 'ru' ? 'Устройство' : 'Device'} - {!twoFlag ? (lang === 'am' ? 'Միացված է' : lang === 'ru' ? 'Включён' : 'enabled') : (lang === 'am' ? 'Անջատված է' : lang === 'ru' ? 'Выключен' : 'disabled')}
                            </Typography>

                        </div>
                    </div>
                </CardContent>
            </Card>
        </>
    );
};

export default memo(DeviceUserGetCounters);