import react from 'react'
import { Oval } from 'react-loading-icons';

const PagePreLoader = () => {
    return (
        <>
            <Oval
            />
        </>
    )
}

export default PagePreLoader