// styles
import "./modals.scss"


import * as React from 'react';
import { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";

// custom imports
import closeButton from '../../images/closeIcon.svg'
//import {goAddTechnician} from "../../redux/actions/addTechnicianAction";
import { goAddUser } from "../../redux/actions/addUserAction";
import { goOffFreeMode, goOnFreeMode, goResetCount } from "../../redux/actions/deviceAction";
import { useNavigate, useParams } from "react-router-dom";
import cookies from 'js-cookie';
import { useEffect } from 'react';
import Swal from "sweetalert2";



export default function FreeModeflag({ idd, one, success }) {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const lang = cookies.get('i18next')

    const handleOpen = async () => {
        if (success) {
            setOpen(true);
        } else {
            await Swal.fire({
                position: 'top-end',
                icon: "error",
                title: "Oops...",
                text: lang === 'am' ? 'Ընտրեք սարքերը' : lang === 'ru' ? 'Выберите устройства' : 'Select the devices',
                timer: 1500,
            });
        }
    };
    const handleClose = () => {
        setOpen(false);
    };

    const token = localStorage.getItem("bestToken")
    const devices = +localStorage.getItem("oneDevice") + 1


    const onFreeModeHandler = e => {
        e.preventDefault()

        let cmdata = {}
        cmdata.devices = { id: [...idd] }
        cmdata.token = localStorage.getItem("bestToken")
        setOpen(false)
        if (!one) {
            dispatch(goOnFreeMode(cmdata))
        } else {
            dispatch(goOffFreeMode(cmdata))
        }


    }


    return (
        <div >
            {!one ?
                <Button className="link" onClick={handleOpen}>{lang === 'am' ? 'Միացնել անվճար ռեժիմը' : lang === 'ru' ? 'Включить бесплатный режим' : 'Free mode on'}</Button>
                :
                <Button className="link" onClick={handleOpen}>{lang === 'am' ? 'Անջատել անվճար ռեժիմը' : lang === 'ru' ? 'Выключить бесплатный режим' : 'Free mode off'}</Button>}
            {open && <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description">

                <div className="container_modal">
                    <form onSubmit={onFreeModeHandler} id="contact">
                        {!one ? <h3>{lang === 'am' ? 'Միացնել անվճար ռեժիմը' : lang === 'ru' ? 'Включить бесплатный режим'
                            : 'Enable free mode'}</h3>
                            :
                            <h3>{lang === 'am' ? 'Անջատել անվճար ռեժիմը' : lang === 'ru' ? 'Выключить бесплатный режим'
                                : 'Disable free mode'}</h3>}
                        <fieldset>
                            {!one ? <button type="submit" id="contact-submit">
                                {lang === 'am' ? 'միացնել' : lang === 'ru' ? 'включить' : 'turn on'}
                            </button>
                                :
                                <button type="submit" id="contact-submit">
                                    {lang === 'am' ? 'անջատել' : lang === 'ru' ? 'выключить' : 'turn off'}
                                </button>}
                        </fieldset>
                        <div onClick={handleClose} className="closeBtn" style={{ display: 'block' }}>
                            <img src={closeButton} onClick={handleClose} alt="close icon" />
                        </div>
                    </form>
                </div>
            </Modal>}
        </div>
    );
}