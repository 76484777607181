export const errorTypes = {
    name: 'Name',
    email: 'Email',
    text: 'Text'
}

export const API_URI = "https://api.zxprof.com/api"

// export const API_URI = "http://localhost:5000/api"

export const token = localStorage.getItem("myToken")

export const myUrl = "https://144.91.122.67:80:3000/"
