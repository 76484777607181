// styles
import "./modals.scss"


import * as React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

// custom imports
import closeButton from '../../images/closeIcon.svg'
//import {goAddTechnician} from "../../redux/actions/addTechnicianAction";
import { useNavigate, useParams } from "react-router-dom";
import { goEditSettings, goEditSettingsBasic } from "../../redux/actions/settingsAction";
import { getCounter } from "../../redux/actions/componentsAction";
import axios from 'axios'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export default function ChangeSettingsBasic({ singleDevice }) {



    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false);
    // const [dataTest, setDataTest] = useState("")
    const lang = cookies.get('i18next')
    const [data, setData] = useState(singleDevice)
    const qweqwe = localStorage.getItem("bestToken")


    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const editSettings = async (e) => {
        e.preventDefault()

        const cmdata = {
            token: qweqwe,
            settings: data
        }

        handleClose()
       await dispatch(goEditSettingsBasic(cmdata))
	await axios.post(`https://api.zxprof.com/cmd/nats-cnf`, { token: cmdata?.token })
        navigate('/loadingPage')
    }



    return (
        <div style={{ zIndex: 500 }} onClick={(e) => e.stopPropagation()}>
            <Button className="link" onClick={handleOpen}>{lang === 'am' ? 'Փոխել հիմնական կարգավորումները' : lang === 'ru' ? 'изменить базовые настройки' : 'Change basic settings'}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description">

                <div className="container_modal">
                    <form onSubmit={editSettings} id="contact"
                        style={{ overflowY: "scroll", height: "500px", justifyContent: "flex-start" }}>
                        <h3>{lang === 'am' ? 'Փոխել հիմնական կարգավորումները' : lang === 'ru' ? 'изменить базовые настройки' : 'Change basic settings'}</h3>
                        {data?.tariff && <fieldset>
                            <h6>{lang === 'am' ? 'Սակագին' : lang === 'ru' ? 'Тариф' : 'Tariff'}</h6>
                            {data.tariff.map((i, k) => (
                                <input
                                    placeholder={k + 1}
                                    type="number"
                                    defaultValue={data.tariff[k]}
                                    onChange={e => {
                                        data.tariff[k] = Number(e.target.value)
                                        setData({ ...data })
                                    }}
                                    tabIndex="3"
                                    required
                                    key={k} />
                            ))}

                        </fieldset>}
                        {(data?.pauseTime || data?.pauseTime === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Դադարի ժամանակը' : lang === 'ru' ? 'Время паузы' : 'Pause time'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Դադարի ժամանակը' : lang === 'ru' ? 'Время паузы' : 'Pause time'}
                                type="number"
                                defaultValue={data.pauseTime}
                                onChange={e => {
                                    data.pauseTime = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.coinNom || data?.coinNom === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Մետաղադրամի նոմինալ' : lang === 'ru' ? 'Номинал монеты' : 'Coin Nominal'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Մետաղադրամի նոմինալ' : lang === 'ru' ? 'Номинал монеты' : 'Coin Nominal'}
                                type="number"
                                defaultValue={data.coinNom}
                                onChange={e => {
                                    data.coinNom = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.billNom || data?.billNom === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Թղթադրամի նոմինալ' : lang === 'ru' ? 'Номинальный счет' : 'Bill Nominal'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Թղթադրամի նոմինալ' : lang === 'ru' ? 'Номинальный счет' : 'Bill Nominal'}
                                type="number"
                                tabIndex="1"
                                defaultValue={data.billNom}
                                onChange={e => {
                                    data.billNom = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                required
                            />
                        </fieldset>}
                        {(data?.bonusP || data?.bonusP === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Բոնուսի տոկոս' : lang === 'ru' ? 'Бонусный процент' : 'Bonus Percent'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Բոնուսի տոկոս' : lang === 'ru' ? 'Бонусный процент' : 'Bonus Percent'}
                                type="number"
                                defaultValue={data.bonusP}
                                onChange={e => {
                                    data.bonusP = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.bonusV || data?.bonusV === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Բոնուսի շեմ' : lang === 'ru' ? 'Бонусный порог' : 'Bonus value'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Բոնուսի շեմ' : lang === 'ru' ? 'Бонусный порог' : 'Bonus value'}
                                type="number"
                                defaultValue={data.bonusV}
                                onChange={e => {
                                    data.bonusV = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.bingoT || data?.bingoT === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Բինգոի շեմ' : lang === 'ru' ? 'Порог призовой суммы' : 'Bingo Threshold'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Բինգոի շեմ' : lang === 'ru' ? 'Порог призовой суммы' : 'Bingo Threshold'}
                                type="number"
                                tabIndex="1"
                                defaultValue={data.bingoT}
                                onChange={e => {
                                    data.bingoT = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                required
                            />
                        </fieldset>}

                        {(data?.bingoV || data?.bingoV === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Բինգոի չափ' : lang === 'ru' ? 'Призовая сумма' : 'Bingo value'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Բինգոի չափ' : lang === 'ru' ? 'Призовая сумма' : 'Bingo value'}
                                type="number"
                                defaultValue={data.bingoV}
                                onChange={e => {
                                    data.bingoV = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}

                        {(data?.schedule?.tStart || data?.schedule?.tStart === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Գիշերային ռեժիմով տարիֆների սկսելու ժամը' : lang === 'ru' ? 'Время начала действия ночных тарифов' : 'Scheduled tariffs start time'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Գիշերային ռեժիմով տարիֆների սկսելու ժամը' : lang === 'ru' ? 'Время начала действия ночных тарифов' : 'Scheduled tariffs start time'}
                                type="text"
                                defaultValue={data.schedule.tStart}
                                onChange={e => {
                                    data.schedule.tStart = e.target.value
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.schedule?.tEnd || data?.schedule?.tEnd === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Գիշերային ռեժիմով տարիֆների ավարտելու ժամը' : lang === 'ru' ? 'Время окончания действия запланированных тарифов' : 'Scheduled tariffs end time'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Գիշերային ռեժիմով տարիֆների ավարտելու ժամը' : lang === 'ru' ? 'Время окончания действия запланированных тарифов' : 'Scheduled tariffs end time'}
                                type="text"
                                defaultValue={data.schedule.tEnd}
                                onChange={e => {
                                    data.schedule.tEnd = e.target.value
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.schedule?.tPct || data?.schedule?.tPct === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Գիշերային ռեժիմով տարիֆների նվազեցման տոկոսը' : lang === 'ru' ? 'Процент снижения скорости для ночного режима' : 'Scheduled tariffs percent'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Գիշերային ռեժիմով տարիֆների նվազեցման տոկոսը' : lang === 'ru' ? 'Процент снижения скорости для ночного режима' : 'Scheduled tariffs percent'}
                                type="number"
                                defaultValue={data.schedule.tPct}
                                onChange={e => {
                                    data.schedule.tPct = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.schedule?.bStart || data?.schedule?.bStart === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Գիշերային ռեժիմով բոնուսի սկսելու ժամը։' : lang === 'ru' ? 'Время начала бонуса ночного режима' : 'Scheduled bonus start time'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Գիշերային ռեժիմով բոնուսի սկսելու ժամը։' : lang === 'ru' ? 'Время начала бонуса ночного режима' : 'Scheduled bonus start time'}
                                type="text"
                                defaultValue={data.schedule.bStart}
                                onChange={e => {
                                    data.schedule.bStart = e.target.value
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.schedule?.bEnd || data?.schedule?.bEnd === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Գիշերային ռեժիմով բոնուսի ավարտելու ժամը' : lang === 'ru' ? 'Время окончания бонуса ночного режима' : 'Scheduled bonus end time'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Գիշերային ռեժիմով բոնուսի ավարտելու ժամը' : lang === 'ru' ? 'Время окончания бонуса ночного режима' : 'Scheduled bonus end time'}
                                type="text"
                                defaultValue={data.schedule.bEnd}
                                onChange={e => {
                                    data.schedule.bEnd = e.target.value
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.schedule?.bPct || data?.schedule?.bPct === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Գիշերային ռեժիմով բոնուսի տոկոսը' : lang === 'ru' ? 'Бонусный процент в ночном режиме' : 'Scheduled Bonus Percent'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Գիշերային ռեժիմով բոնուսի տոկոսը' : lang === 'ru' ? 'Бонусный процент в ночном режиме' : 'Scheduled Bonus Percent'}
                                type="number"
                                defaultValue={data.schedule.bPct}
                                onChange={e => {
                                    data.schedule.bPct = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        {(data?.schedule?.bVal || data?.schedule?.bVal === 0) && <fieldset>
                            <h6>{lang === 'am' ? 'Գիշերային ռեժիմով բոնուսի շեմը' : lang === 'ru' ? 'Бонусный порог ночного режима' : 'Scheduled Bonus Value'}</h6>
                            <input
                                placeholder={lang === 'am' ? 'Գիշերային ռեժիմով բոնուսի շեմը' : lang === 'ru' ? 'Бонусный порог ночного режима' : 'Scheduled Bonus Value'}
                                type="number"
                                defaultValue={data.schedule.bVal}
                                min='0'
                                onChange={e => {
                                    data.schedule.bVal = Number(e.target.value)
                                    setData({ ...data })
                                }}
                                tabIndex="3"
                                required />
                        </fieldset>}
                        <fieldset>
                            <button type="submit" id="contact-submit">
                                {lang === 'am' ? 'Պահպանել' : lang === 'ru' ? 'Сохранить' : 'Save'}
                            </button>
                        </fieldset>
                        <div onClick={handleClose} className="closeBtn">
                            <img src={closeButton} alt="close icon" />
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
}
